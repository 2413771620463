import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import DashboardHome from "./pages/DashboardHome/DashboardHome";
import Login from "./pages/Login/Login";
// import DocManagement from "./pages/DocManagement/DocManagement";
import Setting from "./pages/Setting/Setting";
import CompanyInfo from "./pages/CompanyInfo/CompanyInfo";
import CompanyList from "./pages/CompanyList/CompanyList";
import ViewCompany from "./pages/ViewCompany/ViewCompany";
import Personel from "./pages/Personel/Personel";
import ProtectedRoute from "./routes/ProtectedRoute";
// ==============================================
function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route element={<ProtectedRoute />}>
          <Route path="Dashboard" element={<DashboardHome />} />
          <Route path="Personel" element={<Personel />} />
          {/* <Route path="DocManagement" element={<DocManagement />} /> */}
          <Route path="CompanyList" element={<CompanyList />} />
          <Route path="ViewCompany" element={<ViewCompany />}>
            <Route path=":slug" element={<ViewCompany />} />
          </Route>
          <Route path="CompanyInfo" element={<CompanyInfo />} />
          <Route path="setting" element={<Setting />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
