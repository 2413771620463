import {
  SET_PROFILE,
  SET_PERSONEL_COUNT,
  SET_COMPANIES_COUNT,
  TOGGLE_MODAL_LOADING,
  TOGGLE_LOADING,
} from "./types";
const initialState = {
  profile: "",
  personelCount: 0,
  companiesCount: 0,
  modalLoading: false,
  Loading: false,
};
const myReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_PROFILE:
      return {
        ...state,
        profile: action.payload,
      };
    case SET_PERSONEL_COUNT:
      return {
        ...state,
        personelCount: action.payload,
      };
    case SET_COMPANIES_COUNT:
      return {
        ...state,
        companiesCount: action.payload,
      };
    case TOGGLE_LOADING:
      return {
        ...state,
        Loading: action.payload,
      };
    case TOGGLE_MODAL_LOADING:
      return {
        ...state,
        modalLoading: action.payload,
      };
    default:
      return state;
  }
};

export default myReducer;
