import React from "react";
import "./CompanyInfo.scss";
import Dashboard from "../../components/Dashboard/Dashboard";
function CompanyInfo() {
  return (
    <Dashboard>
      <div className="container p-5 CompanyInfo-wrapper">
        <div className="row">
          <div className="col-sm-12 text-center mb-3">
            <h2>شرکت مهندسی و ساختمان شمس عمران</h2>
          </div>
          <div className="col-sm-12 text-center mb-3">
            <fieldset>
              <legend>اطلاعات سازمان</legend>
              <div className="row">
                <div className="col-4 px-2 text-right my-3">
                  <div className="info-box px-4 py-3 ">
                    <strong>نوع هویت</strong>
                    <span>حقوقی</span>
                  </div>
                </div>
                <div className="col-4 px-2 text-right my-3">
                  <div className="info-box px-4 py-3 ">
                    <strong>شناسایی</strong>
                    <span>ممنوع المعامله</span>
                  </div>
                </div>
                <div className="col-4 px-2 text-right my-3">
                  <div className="info-box px-4 py-3 ">
                    <strong>نام مدیرعامل / فرد</strong>
                    <span>کاربر تستی</span>
                  </div>
                </div>
                <div className="col-4 px-2 text-right my-3">
                  <div className="info-box px-4 py-3 ">
                    <strong>فرزند</strong>
                    <span>تستی</span>
                  </div>
                </div>
                <div className="col-4 px-2 text-right my-3">
                  <div className="info-box px-4 py-3 ">
                    <strong>کد ملی</strong>
                    <span>5555555555</span>
                  </div>
                </div>
                <div className="col-12 px-2 text-right my-3">
                  <div className="info-box px-4 py-3 ">
                    <strong>آخرین اعضای هیئت مدیره</strong>
                    <span>تست</span>
                    <span>تست</span>
                    <span>تست</span>
                    <span>تست</span>
                    <span>تست</span>
                    <span>تست</span>

                  </div>
                </div>
                <div className="col-12 px-2 text-right my-3">
                  <div className="info-box px-4 py-3 ">
                    <strong> اعضای هیئت مدیره قبلی</strong>
                    <span>تست</span>
                    <span>تست</span>
                    <span>تست</span>
                    <span>تست</span>
                    <span>تست</span>
                    <span>تست</span>

                  </div>
                </div>
              </div>
            </fieldset>
          </div>
          <div className="col-sm-12 text-center mb-3">
            <fieldset>
              <legend>اطالاعات پروژه</legend>
              <div className="row">
              <div className="col-4 px-2 text-right my-3">
                <div className="info-box px-4 py-3 ">
                  <strong>نام پروژه</strong>
                  <span>آفرینش</span>
                </div>
              </div>
              <div className="col-4 px-2 text-right my-3">
                <div className="info-box px-4 py-3 ">
                  <strong>تاریخ شروع</strong>
                  <span>1400/01/01</span>
                </div>
              </div>
              <div className="col-4 px-2 text-right my-3">
                <div className="info-box px-4 py-3 ">
                  <strong>مدیر پروژه</strong>
                  <span>مهندس تستی</span>
                </div>
              </div>
              </div>
            </fieldset>
          </div>
        </div>
      </div>
    </Dashboard>
  );
}

export default CompanyInfo;
