import React, { useEffect, useState } from "react";
import "./ViewCompany.scss";
import Dashboard from "../../components/Dashboard/Dashboard";
import AxiosInterceptor from "../../utils/AxiosInterceptor";
import swal from "sweetalert";
import { toggleLoading } from "../../redux/actions";
import defCompany from "../../assets/images/defCompany.jpg";
import defProfile from "../../assets/images/defProfile.jpg";

import { useDispatch } from "react-redux";
import {
  Table,
  Row,
  Col,
  Container,
  Image,
  InputGroup,
  Form,
  FormControl,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import { IMAGEURLS } from "../../utils/Constants";
import { CheckLg, XLg, Search, ArrowLeft, Bricks } from "react-bootstrap-icons";
import { useNavigate } from "react-router-dom";
function ViewCompany() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [companyData, setCompanyData] = useState({
    companyInfo: {},
    directorsList: [],
    personelList: [],
  });
  const [codeMelliSearch, setCodeMelliSearch] = useState("");
  const [sabtNumberSearch, setSabtNumberSearch] = useState("");
  useEffect(() => {
    let companyId = window.location.pathname.replace("/ViewCompany/", "");
    dispatch(toggleLoading(true));
    AxiosInterceptor.post("/Company/List", {
      page: 1,
      pageSize: 15,
      orderBy: "",
      filter: `eCompanyId=${companyId}`,
    }).then((response) => {
      if (response.data.status) {
        let company = response.data.companies.data[0];
        setCodeMelliSearch(company.codeMelli);
        setSabtNumberSearch(company.sabtNumber);
        AxiosInterceptor.post("/CompanyPerson/List", { id: companyId }).then(
          (response) => {
            if (response.data.status) {
              let directors = [],
                personel = [];
              directors = response.data.items.filter(
                (p) => p.type === "هیئت_مدیره"
              );
              personel = response.data.items.filter(
                (p) => p.type === "کارکنان"
              );
              console.log(company);
              setCompanyData({
                companyInfo: company,
                directorsList: directors,
                personelList: personel,
              });
            } else {
              swal({
                icon: "error",
                title: "خطا",
                text: response.data.message,
                button: "متوجه شدم",
              });
            }
            dispatch(toggleLoading(false));
          }
        );
      } else {
        swal({
          icon: "error",
          title: "خطا",
          text: response.data.message,
          button: "متوجه شدم",
        });
      }
    });
  }, [window.location.pathname]);
  const searchCompanyByCodeMelli = () => {
    if (codeMelliSearch !== companyData.companyInfo.codeMelli) {
      dispatch(toggleLoading(true));
      AxiosInterceptor.post("/Company/List", {
        page: 1,
        pageSize: 15,
        orderBy: "",
        filter: `codeMelli=${codeMelliSearch}`,
      }).then((response) => {
        if (response.data.status) {
          if (response.data.companies.count === 0) {
            swal({
              icon: "error",
              title: "خطا",
              text: "نتیجه ای برای این شناسه ملی یافت نشد",
              button: "متوجه شدم",
            });
            setCodeMelliSearch(companyData.companyInfo.codeMelli);
            dispatch(toggleLoading(false));
          } else {
            let company = response.data.companies.data[0];
            navigate("/ViewCompany/" + company.eCompanyId);
          }
        } else {
          swal({
            icon: "error",
            title: "خطا",
            text: response.data.message,
            button: "متوجه شدم",
          });
          dispatch(toggleLoading(false));
        }
      });
    } else {
      swal({
        icon: "warning",
        title: "توجه",
        text: "نتیجه جستجو با شرکتی که هم اکنون در حال مشاهده آن هستید یکسان میباشد.",
        button: "متوجه شدم",
      });
    }
  };
  const searchCompanyBySabtNumber = () => {
    if (sabtNumberSearch !== companyData.companyInfo.sabtNumber) {
      dispatch(toggleLoading(true));
      AxiosInterceptor.post("/Company/List", {
        page: 1,
        pageSize: 15,
        orderBy: "",
        filter: `sabtNumber=${sabtNumberSearch}`,
      }).then((response) => {
        if (response.data.status) {
          if (response.data.companies.data.length === 0) {
            swal({
              icon: "error",
              title: "خطا",
              text: "نتیجه ای برای این شناسه ملی یافت نشد",
              button: "متوجه شدم",
            });
            setSabtNumberSearch(companyData.companyInfo.sabtNumber);
            dispatch(toggleLoading(false));
          } else {
            let company = response.data.companies.data[0];
            navigate("/ViewCompany/" + company.eCompanyId);
          }
        } else {
          swal({
            icon: "error",
            title: "خطا",
            text: response.data.message,
            button: "متوجه شدم",
          });
          dispatch(toggleLoading(false));
        }
      });
    } else {
      swal({
        icon: "warning",
        title: "توجه",
        text: "نتیجه جستجو با شرکتی که هم اکنون در حال مشاهده آن هستید یکسان میباشد.",
        button: "متوجه شدم",
      });
    }
  };

  return (
    <Dashboard>
      <div className="ViewCompany-wrapper">
        <Container fluid className="container">
          <Row className="mb-5">
            <Col sm={12} id="header">
              <div
                id="header_overlay"
                className="d-flex align-items-center justify-content-between"
              >
                <div className="set-column-middle pe-5">
                  <h3 className="text-white">نام شرکت :</h3>
                  <h1 className="text-white">{companyData.companyInfo.name}</h1>
                </div>
                <div className="companyImage-wrapper set-column-middle">
                  <img
                    src={
                      companyData.companyInfo.image
                        ? IMAGEURLS + companyData.companyInfo.image
                        : defCompany
                    }
                    alt="profile"
                  />
                </div>
              </div>
            </Col>
          </Row>
          <Row className="position-relative mb-4">
            <button
              id="goBack"
              className="btn btn-warning"
              onClick={() => {
                navigate("/CompanyList");
              }}
            >
              بازگشت به لیست شرکت ها
              <ArrowLeft className="me-2" />
            </button>
            <Col sm={12} md={6}>
              <Row className="h-100">
                <Col sm={12} md={12} className="my-2">
                  <div className="info-box px-4 py-3">
                    <strong>شناسه ملی</strong>
                    <Form.Group className="input-wrapper">
                      <InputGroup.Text
                        className="py-2 cpointer"
                        onClick={searchCompanyByCodeMelli}
                      >
                        <Search size="1.1em" />
                      </InputGroup.Text>
                      <FormControl
                        name="codeMelliSearch"
                        type="text"
                        value={codeMelliSearch}
                        onChange={(e) => {
                          setCodeMelliSearch(e.target.value);
                        }}
                      />
                    </Form.Group>
                  </div>
                </Col>
                <Col sm={12} className="my-2">
                  <div className="info-box px-4 py-3">
                    <strong>تلفن</strong>
                    <span>{companyData.companyInfo.phone}</span>
                  </div>
                </Col>
                <Col sm={12} md={6} className="my-2">
                  <div className="info-box px-4 py-3 ">
                    <strong>تعداد هیئت مدیره</strong>
                    <span>{companyData.directorsList.length}</span>
                  </div>
                </Col>
                <Col sm={12} md={6} className="my-2">
                  <div className="info-box px-4 py-3 ">
                    <strong>تعداد کارکنان</strong>
                    <span>{companyData.personelList.length}</span>
                  </div>
                </Col>
                {/* <Col sm={6}>
                  <div
                    className="card-counter"
                    style={{ backgroundColor: "var(--color1)" }}
                  >
                    <i className="fa fa-code-fork"></i>
                    <span className="count-numbers">
                    {companyData.directorsList.length}
                    </span>
                    <span className="count-name">تعداد هیئت مدیره</span>
                  </div>
                </Col>
                <Col sm={6}>
                  <div
                    className="card-counter"
                    style={{ backgroundColor: "var(--color2)" }}
                  >
                    <i className="fa fa-ticket"></i>
                    <span className="count-numbers">
                      {companyData.personelList.length}
                    </span>
                    <span className="count-name">تعداد کارکنان</span>
                  </div>
                </Col> */}
              </Row>
            </Col>
            <Col sm={12} md={6}>
              <Row>
                <Col sm={12} md={12} className="my-2">
                  <div className="info-box px-4 py-3 ">
                    <strong>شماره ثبت</strong>
                    <Form.Group className="input-wrapper">
                      <InputGroup.Text
                        className="py-2 cpointer"
                        onClick={searchCompanyBySabtNumber}
                      >
                        <Search size="1.1em" />
                      </InputGroup.Text>
                      <FormControl
                        name="sabtNumberSearch"
                        type="text"
                        value={sabtNumberSearch}
                        onChange={(e) => {
                          setSabtNumberSearch(e.target.value);
                        }}
                      />
                    </Form.Group>
                  </div>
                </Col>
                <Col sm={12} md={12} className="my-2">
                  <div className="info-box px-4 py-3">
                    <strong>کد اقتصادی</strong>
                    <span>{companyData.companyInfo.eghtesadiCode}</span>
                  </div>
                </Col>
                <Col sm={12} md={6} className="my-2">
                  <div className="info-box px-4 py-3 ">
                    <strong>صلاحیت همکاری</strong>
                    {/* 
                          دارد = 0,
                          ندارد = 1,
                          مشکوک_المعامله = 2 
                      */}
                    {companyData.companyInfo.salahiyateHamkariInt === 0 ? (
                      <OverlayTrigger
                        placement="top"
                        delay={{ show: 250, hide: 400 }}
                        overlay={
                          <Tooltip>
                            {companyData.companyInfo.salahiyateHamkari}
                          </Tooltip>
                        }
                      >
                        <span className="text-success cpointer">
                          <CheckLg size="1.3em" />
                        </span>
                      </OverlayTrigger>
                    ) : companyData.companyInfo.salahiyateHamkariInt === 1 ? (
                      <OverlayTrigger
                        placement="top"
                        delay={{ show: 250, hide: 400 }}
                        overlay={
                          <Tooltip>
                            {companyData.companyInfo.salahiyateHamkari}
                          </Tooltip>
                        }
                      >
                        <span className="text-danger cpointer">
                          <XLg size="1.3em" />
                        </span>
                      </OverlayTrigger>
                    ) : (
                      <OverlayTrigger
                        placement="top"
                        delay={{ show: 250, hide: 400 }}
                        overlay={
                          <Tooltip>
                            {companyData.companyInfo.salahiyateHamkari}
                          </Tooltip>
                        }
                      >
                        <span className="text-orange cpointer">
                          <Bricks size="1.3em" />
                        </span>
                      </OverlayTrigger>
                    )}
                  </div>
                </Col>
                <Col sm={12} md={6} className="my-2">
                  <div className="info-box px-4 py-3 ">
                    <strong>سمتا</strong>
                    {companyData.companyInfo.samta ? (
                      <span className="text-success">
                        <CheckLg size="1.3em" />
                      </span>
                    ) : (
                      <span className="text-danger">
                        <XLg size="1.3em" />
                      </span>
                    )}
                  </div>
                </Col>
              </Row>
            </Col>
            <Col sm={12} className="my-2">
              <div className="info-box px-4 py-3">
                <p>
                  <strong>آدرس : </strong>
                  {companyData.companyInfo.address}
                </p>
              </div>
            </Col>
            <Col sm={12} md={12}>
              <fieldset>
                <legend>لیست هیئت مدیره</legend>
                <Table striped bordered hover responsive>
                  <thead className="gradiantBg text-white text-center">
                    <tr>
                      <th scope="col" className="text-nowrap">
                        ردیف
                      </th>
                      <th scope="col" className="text-nowrap">
                        تصویر
                      </th>
                      <th scope="col" className="text-nowrap">
                        نام
                      </th>
                      <th scope="col" className="text-nowrap">
                        نام خانوادگی
                      </th>
                      <th scope="col" className="text-nowrap">
                        کد ملی
                      </th>
                      <th scope="col" className="text-nowrap">
                        سمت
                      </th>
                      <th scope="col" className="text-nowrap">
                        وضعیت
                      </th>
                      <th scope="col" className="text-nowrap">
                        صلاحیت همکاری
                      </th>
                    </tr>
                  </thead>
                  <tbody className="text-center">
                    {companyData.directorsList?.length > 0 ? (
                      companyData.directorsList?.map((di, index) => {
                        return (
                          <tr key={index} id={di.eCompanyPersonId}>
                            <td className="text-nowrap">{index + 1}</td>
                            <td className="text-nowrap set-row-middle">
                              <div style={{ width: "40px", height: "40px" }}>
                                <Image
                                  className="cpointer zoomable_avatar"
                                  src={
                                    di.eLitePerson.image
                                      ? IMAGEURLS + di.eLitePerson.image
                                      : defProfile
                                  }
                                  width="40px"
                                  height="40px"
                                />
                              </div>
                            </td>
                            <td className="text-nowrap">
                              {di.eLitePerson.name}
                            </td>
                            <td className="text-nowrap">
                              {di.eLitePerson.family}
                            </td>
                            <td className="text-nowrap">
                              {di.eLitePerson.codeMelli}
                            </td>
                            <td className="text-nowrap">
                              {di.jobPositionDisplay}
                            </td>
                            <td className="text-nowrap">
                              {di.status ? "فعلی" : "سابق"}
                            </td>
                            <td className="text-nowrap">
                              {di.eLitePerson.salahiyateHamkariInt === 0 ||
                              di.eLitePerson.salahiyateHamkariInt === 2 ? (
                                <OverlayTrigger
                                  placement="top"
                                  delay={{ show: 250, hide: 400 }}
                                  overlay={
                                    <Tooltip>
                                      {di.eLitePerson.salahiyateHamkari}
                                    </Tooltip>
                                  }
                                >
                                  <span className="text-success cpointer">
                                    <CheckLg size="1.3em" />
                                  </span>
                                </OverlayTrigger>
                              ) : (
                                <OverlayTrigger
                                  placement="top"
                                  delay={{ show: 250, hide: 400 }}
                                  overlay={
                                    <Tooltip>
                                      {di.eLitePerson.salahiyateHamkari}
                                    </Tooltip>
                                  }
                                >
                                  <span className="text-danger cpointer">
                                    <XLg size="1.3em" />
                                  </span>
                                </OverlayTrigger>
                              )}
                            </td>
                          </tr>
                        );
                      })
                    ) : (
                      <tr>
                        <td colSpan={7}>لیست هیئت مدیره خالی میباشد</td>
                      </tr>
                    )}
                  </tbody>
                </Table>
              </fieldset>
            </Col>
            <Col sm={12} md={12}>
              <fieldset>
                <legend>لیست کارکنان</legend>
                <Table striped bordered hover responsive>
                  <thead className="gradiantBg text-white text-center">
                    <tr>
                      <th scope="col" className="text-nowrap">
                        ردیف
                      </th>
                      <th scope="col" className="text-nowrap">
                        تصویر
                      </th>
                      <th scope="col" className="text-nowrap">
                        نام
                      </th>
                      <th scope="col" className="text-nowrap">
                        نام خانوادگی
                      </th>
                      <th scope="col" className="text-nowrap">
                        کد ملی
                      </th>
                      <th scope="col" className="text-nowrap">
                        سمت
                      </th>
                      <th scope="col" className="text-nowrap">
                        صلاحیت همکاری
                      </th>
                    </tr>
                  </thead>
                  <tbody className="text-center">
                    {companyData.personelList?.length > 0 ? (
                      companyData.personelList?.map((pe, index) => {
                        return (
                          <tr key={index} id={pe.eCompanyPersonId}>
                            <td className="text-nowrap">{index + 1}</td>
                            <td className="text-nowrap set-row-middle">
                              <div style={{ width: "40px", height: "40px" }}>
                                <Image
                                  className="cpointer zoomable_avatar"
                                  src={
                                    pe.eLitePerson.image
                                      ? IMAGEURLS + pe.eLitePerson.image
                                      : defProfile
                                  }
                                  width="40px"
                                  height="40px"
                                />
                              </div>
                            </td>
                            <td className="text-nowrap">
                              {pe.eLitePerson.name}
                            </td>
                            <td className="text-nowrap">
                              {pe.eLitePerson.family}
                            </td>
                            <td className="text-nowrap">
                              {pe.eLitePerson.codeMelli}
                            </td>
                            <td className="text-nowrap">
                              {pe.jobPositionDisplay}
                            </td>
                            <td className="text-nowrap">
                              {pe.eLitePerson.salahiyateHamkariInt === 0 ||
                              pe.eLitePerson.salahiyateHamkariInt === 2 ? (
                                <OverlayTrigger
                                  placement="top"
                                  delay={{ show: 250, hide: 400 }}
                                  overlay={
                                    <Tooltip>
                                      {pe.eLitePerson.salahiyateHamkari}
                                    </Tooltip>
                                  }
                                >
                                  <span className="text-success cpointer">
                                    <CheckLg size="1.3em" />
                                  </span>
                                </OverlayTrigger>
                              ) : (
                                <OverlayTrigger
                                  placement="top"
                                  delay={{ show: 250, hide: 400 }}
                                  overlay={
                                    <Tooltip>
                                      {pe.eLitePerson.salahiyateHamkari}
                                    </Tooltip>
                                  }
                                >
                                  <span className="text-danger cpointer">
                                    <XLg size="1.3em" />
                                  </span>
                                </OverlayTrigger>
                              )}
                            </td>
                          </tr>
                        );
                      })
                    ) : (
                      <tr>
                        <td colSpan={7}>لیست پرسنل خالی میباشد</td>
                      </tr>
                    )}
                  </tbody>
                </Table>
              </fieldset>
            </Col>
          </Row>
        </Container>
      </div>
    </Dashboard>
  );
}

export default ViewCompany;
