import {
  SET_PROFILE,
  SET_PERSONEL_COUNT,
  SET_COMPANIES_COUNT,
  TOGGLE_MODAL_LOADING,
  TOGGLE_LOADING,
} from "./types";
export const setImageProfile = (params) => {
  return {
    type: SET_PROFILE,
    payload: params,
  };
};
export const setPersonelCount = (params) => {
  return {
    type: SET_PERSONEL_COUNT,
    payload: params,
  };
};
export const setCompaniesCount = (params) => {
  return {
    type: SET_COMPANIES_COUNT,
    payload: params,
  };
};
export const toggleModalLoading = (params) => {
  return {
    type: TOGGLE_MODAL_LOADING,
    payload: params,
  };
};
export const toggleLoading = (params) => {
  return {
    type: TOGGLE_LOADING,
    payload: params,
  };
};
