import React, { useState, useEffect } from "react";
import { Trash, PencilSquare, CheckLg, XLg } from "react-bootstrap-icons";
import { InputGroup, FormControl, Col } from "react-bootstrap";
import AxiosInterceptor from "../../utils/AxiosInterceptor";
import swal from "sweetalert";
import EnumSelector from "../../components/EnumSelector/EnumSelector";
function EnumItemHasParent({ enumType, name, items, parent }) {
    const [enumData, setEnumData] = useState({
        newEnumDataTitle: "",
        newEnumDataTitleErr: "",
        newEnumDataParentId: "",
        newEnumDataParentIdErr: "",
        editedEnumDataTitle: "",
        editedEnumDataTitleErr: false,
        editedEnumDataParentId: "",
        editMode: false,
        editedIdentity: "",
        list: items,
    });
    const [staticDatas, setStaticDatas] = useState({})
    const saveEnum = () => {
        let myList = [];
        let isValid = true;
        if (enumData.newEnumDataTitle === "") {
            setEnumData((prevState) => ({
                ...prevState,
                newEnumDataTitleErr: `مقدار فیلد ${name} جدید نمیتواند خالی باشد`,
            }));
            isValid = false;
        } else {
            setEnumData((prevState) => ({
                ...prevState,
                newEnumDataTitleErr: "",
            }));
        }
        if (enumData.newEnumDataParentId === "") {
            setEnumData((prevState) => ({
                ...prevState,
                newEnumDataParentIdErr: `مقدار فیلد ${parent.name} نمیتواند خالی باشد`,
            }));
            isValid = false;
        } else {
            setEnumData((prevState) => ({
                ...prevState,
                newEnumDataParentIdErr: "",
            }));
        }
        if (isValid) {
            let newD = [
                {
                    key: enumData.list.length + 1,
                    value: enumData.newEnumDataTitle,
                    parentValue: enumData.newEnumDataParentId
                },
            ];
            myList = [...enumData.list, ...newD];
            AxiosInterceptor.post("/Setting/Edit", {
                key: enumType,
                value: JSON.stringify(myList),
            }).then((response) => {
                if (response.data.status) {
                    setEnumData({
                        ...enumData,
                        newEnumDataTitle: "",
                        newEnumDataTitleErr: "",
                        newEnumDataParentId: "",
                        newEnumDataParentIdErr: "",
                        list: JSON.parse(response.data.setting.value),
                    });
                } else {
                    swal({
                        icon: "error",
                        title: "خطا",
                        text: response.data.message,
                        button: "متوجه شدم",
                    });
                }
            });
        }
    };
    const deleteEnum = (dckey, dcValue) => {
        swal({
            title: "مطمئن هستید؟",
            text: " آیا از حذف " + dcValue + " اطمینان دارید؟ ",
            icon: "warning",
            buttons: ["خیر", "بله"],
            dangerMode: true,
        }).then((willDelete) => {
            if (willDelete) {
                let counter = 1;
                let myList = [];
                enumData.list.forEach((dc) => {
                    if (dc.key !== dckey) {
                        dc.key = counter;
                        counter++;
                        myList = [...myList, dc];
                    }
                });
                AxiosInterceptor.post("/Setting/Edit", {
                    key: enumType,
                    value: JSON.stringify(myList),
                }).then((response) => {
                    if (response.data.status) {
                        setEnumData({
                            ...enumData,
                            newEnumDataTitle: "",
                            newEnumDataTitleErr: "",
                            list: JSON.parse(response.data.setting.value),
                        });
                    } else {
                        swal({
                            icon: "error",
                            title: "خطا",
                            text: response.data.message,
                            button: "متوجه شدم",
                        });
                    }
                });
            }
        });
    };
    const updateEnum = () => {
        let myList = enumData.list;
        let isValid = true;
        if (enumData.editedEnumDataParentId === "" || enumData.editedEnumDataTitle === "") {
            swal({
                icon: "error",
                title: "خطا",
                text: "انتخاب و پر کردم همه فیلد ها الزامی است",
                button: "متوجه شدم",
            });
            isValid = false;
        }
        if (isValid) {
            myList.forEach((el) => {
                if (el.key === enumData.editedIdentity) {
                    el.value = enumData.editedEnumDataTitle;
                    el.parentValue = enumData.editedEnumDataParentId;
                }
            });
            AxiosInterceptor.post("/Setting/Edit", {
                key: enumType,
                value: JSON.stringify(myList),
            }).then((response) => {
                if (response.data.status) {
                    setEnumData((prevState) => ({
                        ...prevState,
                        editedEnumDataTitle: "",
                        editedEnumDataTitleErr: false,
                        editedEnumDataParentId: "",
                        list: JSON.parse(response.data.setting.value),
                    }));
                } else {
                    swal({
                        icon: "error",
                        title: "خطا",
                        text: response.data.message,
                        button: "متوجه شدم",
                    });
                }
                setEnumData((prevState) => ({
                    ...prevState,
                    editMode: false,
                }));
            });
        }
    };
    useEffect(() => {
        AxiosInterceptor.post("/Setting/List", {
            page: 1,
            pageSize: 100,
            orderBy: "",
            filter: `key=${parent.parentEnumType}`,
        }).then((response) => {
            if (response.data.status) {
                let obj = {};
                response.data.settings.data.forEach((item) => {
                    obj[item.key] = JSON.parse(item.value);
                });
                setStaticDatas(obj);
            } else {
                swal({
                    icon: "error",
                    title: "خطا",
                    text: response.data.message,
                    button: "متوجه شدم",
                });
            }
        });
    }, [])
    return (
        <Col sm={12} lg={6}>
            <div className="text-center">
                <h5>{name}</h5>
            </div>
            <div className="set-row-middle">
                <div className="input-wrapper flex-column">
                    <InputGroup className="mb-2">
                        <InputGroup.Text>{name + " جدید"}</InputGroup.Text>
                        <FormControl
                            type="text"
                            className={enumData.newEnumDataTitleErr ? "errorBorder" : ""}
                            name="newEnumDataTitle"
                            value={enumData.newEnumDataTitle}
                            onChange={(e) => {
                                setEnumData({
                                    ...enumData,
                                    newEnumDataTitle: e.target.value,
                                });
                            }}
                            autoComplete="new-password"
                        />
                    </InputGroup>
                    <EnumSelector
                        data={staticDatas}
                        enumKey={parent.parentEnumType}
                        name="newEnumDataParentId"
                        title={parent.name}
                        value={enumData.newEnumDataParentId}
                        onChange={(e) => {
                            setEnumData({
                                ...enumData,
                                newEnumDataParentId: e.target.value,
                            });
                        }}
                    />
                </div>
                <button
                    className="btn btn-success m-2 mt-0"
                    onClick={() => saveEnum()}
                >
                    افزودن+
                </button>
            </div>
            <p className="error text-center">{enumData.newEnumDataTitleErr}</p>
            <p className="error text-center">{enumData.newEnumDataParentIdErr}</p>
            <ul className="setting-list-group list-group px-0">
                {enumData.list.map((dc) => {
                    return (
                        <li className="list-group-item" id={dc.key} key={dc.key}>
                            {enumData.editMode && enumData.editedIdentity === dc.key ? (
                                <>
                                    <div className="set-row-middle float-end" style={{width:"fit-contnet"}}>
                                        <input
                                            type="text"
                                            className={`text-center ms-1 px-2 ${enumData.editedEnumDataTitleErr ? "errorBorder" : ""
                                                }`}
                                            value={enumData.editedEnumDataTitle}
                                            onChange={(e) => {
                                                setEnumData((prevState) => ({
                                                    ...prevState,
                                                    editedEnumDataTitle: e.target.value,
                                                }));
                                            }}
                                        />
                                        <EnumSelector
                                            data={staticDatas}
                                            enumKey={parent.parentEnumType}
                                            name="editedEnumDataParentId"
                                            title={parent.name}
                                            value={enumData.editedEnumDataParentId}
                                            onChange={(e) => {
                                                setEnumData({
                                                    ...enumData,
                                                    editedEnumDataParentId: e.target.value,
                                                });
                                            }}
                                        />
                                    </div>
                                    <span
                                        className="float-start me-2 cpointer"
                                        onClick={() => {
                                            setEnumData((prevState) => ({
                                                ...prevState,
                                                editMode: false,
                                            }));
                                        }}
                                    >
                                        <XLg color={"#DC3545"} />
                                    </span>
                                    <span
                                        className="float-start cpointer"
                                        onClick={() => {
                                            updateEnum();
                                        }}
                                    >
                                        <CheckLg color={"#28A745"} />
                                    </span>
                                </>
                            ) : (
                                <>
                                    {dc.value + " (" + staticDatas[parent.parentEnumType]?.filter(item => item.key === +dc.parentValue).map(item => item.value) + ") "}
                                    <span
                                        className="float-start me-2 cpointer"
                                        onClick={() => {
                                            deleteEnum(dc.key, dc.value);
                                        }}
                                    >
                                        <Trash color={"#DC3545"} />
                                    </span>
                                    <span
                                        className="float-start cpointer"
                                        onClick={() => {
                                            setEnumData((prevState) => ({
                                                ...prevState,
                                                editedEnumDataTitle: dc.value,
                                                editMode: true,
                                                editedIdentity: dc.key,
                                                editedEnumDataParentId: dc.parentValue
                                            }));
                                        }}
                                    >
                                        <PencilSquare color={"#FFC107"} />
                                    </span>
                                </>
                            )}
                        </li>
                    );
                })}
            </ul>
        </Col>
    );
}

export default EnumItemHasParent;
