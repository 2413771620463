import React from "react";
import {
    InputGroup,
    Form,
} from "react-bootstrap";
function EnumSelectorHasParent({ data, enumKey, name, title, value, onChange, parentEnumKey }) {
    return (
        <Form.Group
            className="set-row-middle"
            controlId={name}
        >
            <InputGroup.Text>{title}</InputGroup.Text>
            <Form.Select
                value={value}
                name={name}
                onChange={onChange}
            >
                <option value={0}>انتخاب کنید</option>
                {data[enumKey]?.map((op, index) => {
                    return (
                        <option
                            value={op.key}
                            key={index}
                            id={op.key}
                        >
                            {op.value + " (" + data[parentEnumKey]?.filter(item => item.key === +op.parentValue).map(item => item.value) + ") "}
                        </option>
                    );
                })}
            </Form.Select>
        </Form.Group>
    )
}

export default EnumSelectorHasParent
