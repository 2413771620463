import React from "react";
import { useSelector } from "react-redux";
import Indicator from "./Indicator";
import "./Loading.scss";
function Loading() {
  const reduxState = useSelector((state) => state);
  return (
    <div
      id="loading-wrapper"
      className={reduxState.Loading ? "set-column-middle" : "d-none"}
    >
      <Indicator />
      <h4 className="text-white mt-4">در حال بارگذاری اطلاعات</h4>
      <p className="text-white">لطفا صبر کنید ...</p>
    </div>
  );
}

export default Loading;
