import axios from "axios";
import swal from "sweetalert";
import { BASEURL } from "./Constants";
import { toggleLoading, toggleModalLoading } from "../redux/actions";
import store from "../redux/store";
const AxiosInterceptor = axios.create({
  baseURL: `${BASEURL}/api`,
});
AxiosInterceptor.interceptors.request.use((config) => {
  let _authToken = "";
  if(localStorage.getItem("_data")){
    _authToken = JSON.parse(localStorage.getItem("_data")).token;
  }
  config.headers.Authorization = "Bearer " + _authToken;
  return config;
});
AxiosInterceptor.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    console.error(error);
    if (error.response) {
      if (error.response.status === 401) {
        swal({
          icon: "error",
          title: "خطا",
          text: "توکن احراز هویت شما منقضی شده است\nلطفا دوباره وراد شوید.",
          button: "متوجه شدم",
        });
        setTimeout(() => {
          window.location.href = "/";
        }, 4000);
      }else{
        swal({
          icon: "error",
          title: "خطا",
          text: "خطا در برقراری ارتباط و صحت اطلاعات.\nلطفا دوباره تلاش نمایید.",
          button: "متوجه شدم",
        });
      }
    }
    store.dispatch(toggleLoading(false));
    store.dispatch(toggleModalLoading(false));
    return Promise.reject(error);
  }
);
export default AxiosInterceptor;
