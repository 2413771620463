import React, { useEffect, useState, useRef } from "react";
import ReactDOM from "react-dom";
import "./CompanyList.scss";
import {
  Table,
  Row,
  Col,
  Modal,
  InputGroup,
  FormControl,
  Form,
  Image,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import {
  PencilSquare,
  Trash,
  InfoSquareFill,
  ArrowRight,
  ArrowLeftCircleFill,
  ArrowLeft,
  XLg,
  EyeFill,
  CheckLg,
  CloudArrowUpFill,
  Trash2,
  Eye,
  Bricks,
} from "react-bootstrap-icons";
import Dashboard from "../../components/Dashboard/Dashboard";
import AxiosInterceptor from "../../utils/AxiosInterceptor";
import swal from "sweetalert";
import {
  setCompaniesCount,
  toggleLoading,
  toggleModalLoading,
} from "../../redux/actions";
import { useSelector, useDispatch } from "react-redux";
import Portal from "../../components/Portal/Portal";
import defProfile from "../../assets/images/defProfile.jpg";
import defCompany from "../../assets/images/defCompany.jpg";
import { useNavigate } from "react-router-dom";
import { IMAGEURLS } from "../../utils/Constants";
import EnumSelector from "../../components/EnumSelector/EnumSelector";
import { DateInput } from "react-hichestan-datetimepicker";
import IRCheck from "ircheck";

function CompanyList() {
  const navigate = useNavigate();
  const tableHeightRef = useRef(null);
  const reduxState = useSelector((state) => state);
  const dispatch = useDispatch();
  const [canChangeCompanyCodeMelli, setCanChangeCompanyCodeMelli] =
    useState(false);
  const [staticDatas, setStaticDatas] = useState({});
  const [companyList, setCompanyList] = useState([]);
  const [paginationFilter, setPaginationFilter] = useState({
    pointer: 1,
    items: [],
    docNumberPerPage: "15",
    goTo: "",
  });
  const [companyModal, setCompanyModal] = useState({
    show: false,
    codeMelliIsOk: false,
  });
  const initialCompanyInputs = {
    eCompanyId: "",
    name: "",
    codeMelli: "",
    sabtNumber: "",
    address: "",
    phone: "",
    image: "",
    eghtesadiCode: "",
    samta: false,
    hozeFaaliyat: 0,
    hamkariType: 0
  };
  const [companyInputs, setCompanyInputs] = useState(initialCompanyInputs);
  const [companyInputsErr, setCompanyInputsErr] = useState({
    codeMelliErr: "",
    eghtesadiCodeErr: "",
    sabtNumberErr: "",
  });
  const [personMembersModal, setPersonMembersModal] = useState({
    show: false,
    type: "", // directors -- personel
    eCompanyId: "",
    addingPersonMode: false,
    list: [],
  });
  const [litePersonInput, setLitePersonInput] = useState({
    eCompanyPersonId: "",
    eLitePersonId: "",
    name: "",
    family: "",
    codeMelli: "",
    image: "",
    jobPosition: 0,
    status: true,
    hasGozarname: false,
    gozarnameNumber: "",
    gozarnameMailNumber: "",
    gozarnameFile: "",
    gozarnameDate: "",
  });
  const [litePersonInputErr, setLitePersonInputErr] = useState({
    codeMelliErr: "",
  });
  const getStaticData = () => {
    AxiosInterceptor.post("/Setting/List", {
      page: 1,
      pageSize: 25,
      orderBy: "",
      filter: "",
    }).then((response) => {
      if (response.data.status) {
        let obj = {};
        response.data.settings.data.forEach((item) => {
          obj[item.key] = JSON.parse(item.value);
        });
        setStaticDatas(obj);
      } else {
        swal({
          icon: "error",
          title: "خطا",
          text: response.data.message,
          button: "متوجه شدم",
        });
      }
    });
  };
  /* ===================================== 
================== Company Modal =================== 
  ===================================== 
*/
  const confirmcodeMelli = () => {
    if (companyInputs.codeMelli === "") {
      setCompanyInputsErr({
        ...companyInputsErr,
        codeMelliErr: "مقدار شناسه ملی نمیتواند خالی باشد",
      });
    } else if (companyInputs.codeMelli.length !== 11) {
      setCompanyInputsErr({
        ...companyInputsErr,
        codeMelliErr: "لطفا شناسه ملی معتبر وارد کنید",
      });
    } else {
      AxiosInterceptor.post("/Company/List", {
        page: 1,
        pageSize: 15,
        orderBy: "",
        filter: `codeMelli=${companyInputs.codeMelli}`,
      }).then((response) => {
        if (response.data.status) {
          if (response.data.companies.count === 0) {
            setCompanyInputs({
              ...companyInputs,
              codeMelli: companyInputs.codeMelli,
            });
          } else {
            setCompanyInputs(response.data.companies.data[0]);
          }
          setCompanyInputsErr({
            ...companyInputsErr,
            codeMelliErr: "",
          });
          setCompanyModal({
            ...companyModal,
            codeMelliIsOk: true,
          });
        } else {
          swal({
            icon: "error",
            title: "خطا",
            text: response.data.message,
            button: "متوجه شدم",
          });
        }
      });
    }
  };
  const companyOnChangeHandle = (e) => {
    let { name, value, type } = e.target;
    if (type === "number") {
      setCompanyInputs({
        ...companyInputs,
        [name]: +value,
      });
    } else if (type === "select-one") {
      if (value === "انتخاب کنید") {
        setCompanyInputs({
          ...companyInputs,
          [name]: null,
        });
      } else if (value === "true") {
        setCompanyInputs({
          ...companyInputs,
          [name]: true,
        });
      } else if (value === "false") {
        setCompanyInputs({
          ...companyInputs,
          [name]: false,
        });
      } else {
        setCompanyInputs({
          ...companyInputs,
          [name]: +value,
        });
      }
    } else {
      setCompanyInputs({
        ...companyInputs,
        [name]: value,
      });
    }
  };
  const getAllCompanies = () => {
    dispatch(toggleLoading(true));
    AxiosInterceptor.post("/Company/List", {
      page: 1,
      pageSize: +paginationFilter.docNumberPerPage,
      orderBy: "",
      filter: "",
    }).then((response) => {
      if (response.data.status) {
        setCompanyList(response.data.companies.data);
        dispatch(setCompaniesCount(response.data.companies.count));
      } else {
        swal({
          icon: "error",
          title: "خطا",
          text: response.data.message,
          button: "متوجه شدم",
        });
      }
      dispatch(toggleLoading(false));
    });
  };
  const editCompany = (company) => {
    setCompanyInputs(company);
    setCompanyModal({
      show: true,
      codeMelliIsOk: true,
    });
  };
  const getThisPageCompanies = (page) => {
    dispatch(toggleLoading(true));
    AxiosInterceptor.post("/Company/List", {
      page: page,
      pageSize: paginationFilter.docNumberPerPage,
      orderBy: "",
      filter: "",
    }).then((response) => {
      if (response.data.status) {
        setCompanyList(response.data.companies.data);
        dispatch(setCompaniesCount(response.data.companies.count));
        setPaginationFilter((prevState) => ({
          ...prevState,
          pointer: page,
          goTo: "",
        }));
      } else {
        swal({
          icon: "error",
          title: "خطا",
          text: response.data.message,
          button: "متوجه شدم",
        });
      }
      dispatch(toggleLoading(false));
    });
  };
  const ADDorUPDATEcompany = (actionType) => {
    let isValid = true;
    if (companyInputs.eghtesadiCode.length !== 12) {
      setCompanyInputsErr((prevState) => ({
        ...prevState,
        eghtesadiCodeErr: "کد اقتصادی باید 12 رقمی باشد.",
      }));
      isValid = false;
    } else {
      setCompanyInputsErr((prevState) => ({
        ...prevState,
        eghtesadiCodeErr: "",
      }));
    }
    if (companyInputs.sabtNumber.length > 15) {
      setCompanyInputsErr((prevState) => ({
        ...prevState,
        sabtNumberErr: "شماره ثبت باید کمتر از 15 رقم باشد.",
      }));
      isValid = false;
    } else {
      setCompanyInputsErr((prevState) => ({
        ...prevState,
        sabtNumberErr: "",
      }));
    }
    if (companyInputs.codeMelli === "") {
      setCompanyInputsErr((prevState) => ({
        ...prevState,
        codeMelliErr: "مقدار شناسه ملی نمیتواند خالی باشد",
      }));
      isValid = false;
    } else if (companyInputs.codeMelli.length !== 11) {
      setCompanyInputsErr((prevState) => ({
        ...prevState,
        codeMelliErr: "لطفا شناسه ملی معتبر وارد کنید",
      }));
      isValid = false;
    } else {
      setCompanyInputsErr((prevState) => ({
        ...prevState,
        codeMelliErr: "",
      }));
    }
    if (isValid) {
      setCanChangeCompanyCodeMelli(false);
      dispatch(toggleModalLoading(true));
      let data = { ...companyInputs };
      let msg = "";
      if (actionType === "ADD") {
        delete data.eCompanyId;
        msg = "شرکت مورد نظر با موفقیت ثبت گردید.";
      } else {
        msg = "شرکت مورد نظر با موفقیت به روز رسانی گردید.";
      }
      AxiosInterceptor.post("/Company/Edit", data).then((response) => {
        if (response.data.status) {
          swal({
            icon: "success",
            title: "موفقیت",
            text: msg,
            button: "متوجه شدم",
          });
          if (actionType === "ADD") {
            setCompanyInputs({
              ...companyInputs,
              eCompanyId: response.data.company.eCompanyId,
            });
          }
          getAllCompanies();
        } else {
          swal({
            icon: "error",
            title: "خطا",
            text: response.data.message,
            button: "متوجه شدم",
          });
        }
        dispatch(toggleModalLoading(false));
      });
    }
  };
  const deleteCompany = (cId) => {
    let alertInput = document.createElement("div");
    ReactDOM.render(
      <Form.Control id="adminPassword" name="adminPassword" type="password" autoComplete="new-password" />,
      alertInput
    );
    let el = alertInput.firstChild;
    swal("برای حذف شرکت باید کلمه عبور ادمین را وارد نمایید.", {
      content: el,
      buttons: ["لغو", "حذف"],
    }).then((value) => {
      if (value) {
        AxiosInterceptor.post("/Company/Delete", {
          id: cId,
          password: el.value,
        }).then((response) => {
          if (response.data.status) {
            swal({
              icon: "success",
              title: "موفقیت",
              text: "شرکت مورد نظر با موفقیت حذف گردید",
              button: "متوجه شدم",
            });
            getAllCompanies();
          } else {
            swal({
              icon: "error",
              title: "خطا",
              text: response.data.message,
              button: "متوجه شدم",
            });
          }
        });
      }
    });
  };
  const getAccessToChangeCodeMelli = () => {
    let alertInput = document.createElement("div");
    document.querySelector(".modal").removeAttribute("tabIndex");
    ReactDOM.render(
      <Form.Control
        id="adminPassword"
        name="adminPassword"
        type="password"
        autoComplete="new-password"
      />,
      alertInput
    );
    let el = alertInput.firstChild;
    swal("برای ویرایش باید کلمه عبور ادمین را وارد نمایید.", {
      content: el,
      buttons: ["لغو", "تایید"],
    }).then((value) => {
      if (value) {
        AxiosInterceptor.post("/Account/IsAadmin", {
          password: el.value,
        }).then((response) => {
          if (response.data.status) {
            setCanChangeCompanyCodeMelli(true);
            swal({
              icon: "success",
              title: "موفقیت",
              text: "هم اکنون شما مجاز به ویرایش شناسه ملی هستید.",
              button: "متوجه شدم",
            });
          } else {
            swal({
              icon: "error",
              title: "خطا",
              text: "رمز عبور وارد شده اشتباه است.",
              button: "متوجه شدم",
            });
          }
        });
      }
    });
  };
  /* ===================================== 
================== Company Person =================== 
  ===================================== 
*/
  /* ===================================== 
================== Lite Person =================== 
  ===================================== 
*/
  const getAllPersons = (eCompanyId, type) => {
    //types 0=heyatModire, 1=karkonan
    dispatch(toggleModalLoading(true));
    AxiosInterceptor.post("/CompanyPerson/List", { id: eCompanyId }).then(
      (response) => {
        if (response.data.status) {
          let data = [];
          if (type === "directors") {
            data = response.data.items.filter((p) => p.type === "هیئت_مدیره");
          } else {
            data = response.data.items.filter((p) => p.type === "کارکنان");
          }
          setPersonMembersModal((prevState) => ({
            ...prevState,
            list: data,
          }));
        } else {
          swal({
            icon: "error",
            title: "خطا",
            text: response.data.message,
            button: "متوجه شدم",
          });
        }
        dispatch(toggleModalLoading(false));
      }
    );
  };
  const litePersonOnChangeHandle = (e) => {
    let { name, value, type } = e.target;
    if (type === "number") {
      setLitePersonInput({
        ...litePersonInput,
        [name]: +value,
      });
    } else if (type === "select-one") {
      if (value === "انتخاب کنید") {
        setLitePersonInput({
          ...litePersonInput,
          [name]: null,
        });
      } else if (value === "true") {
        setLitePersonInput({
          ...litePersonInput,
          [name]: true,
        });
      } else if (value === "false") {
        setLitePersonInput({
          ...litePersonInput,
          [name]: false,
        });
      } else {
        setLitePersonInput({
          ...litePersonInput,
          [name]: +value,
        });
      }
    } else {
      if (name === "codeMelli") {
        if (value.length < 11) {
          setLitePersonInput({
            ...litePersonInput,
            [name]: value,
          });
        }
      } else {
        setLitePersonInput({
          ...litePersonInput,
          [name]: value,
        });
      }
    }
  };
  const editCompanyPerson = (companyPerson) => {
    setLitePersonInput({
      eCompanyPersonId: companyPerson.eCompanyPersonId,
      eLitePersonId: companyPerson.eLitePerson.eLitePersonId,
      name: companyPerson.eLitePerson.name
        ? companyPerson.eLitePerson.name
        : "",
      family: companyPerson.eLitePerson.family
        ? companyPerson.eLitePerson.family
        : "",
      codeMelli: companyPerson.eLitePerson.codeMelli
        ? companyPerson.eLitePerson.codeMelli
        : "",
      image: companyPerson.eLitePerson.image
        ? companyPerson.eLitePerson.image
        : "",
      jobPosition: companyPerson.jobPosition ? companyPerson.jobPosition : 0,
      status: companyPerson.status,
      hasGozarname: companyPerson.eLitePerson.hasGozarname,
      gozarnameNumber: companyPerson.eLitePerson.gozarnameNumber
        ? companyPerson.eLitePerson.gozarnameNumber
        : "",
      gozarnameMailNumber: companyPerson.eLitePerson.gozarnameMailNumber
        ? companyPerson.eLitePerson.gozarnameMailNumber
        : "",
      gozarnameFile: companyPerson.eLitePerson.gozarnameFile
        ? companyPerson.eLitePerson.gozarnameFile
        : "",
      gozarnameDate: companyPerson.eLitePerson.gozarnameDate
        ? companyPerson.eLitePerson.gozarnameDate
        : "",
    });
    setPersonMembersModal({
      ...personMembersModal,
      addingPersonMode: true,
    });
  };
  const ADDorUPDATECompanyPerson = (actionType) => {
    if (litePersonInput.codeMelli === "") {
      setLitePersonInputErr({
        ...litePersonInputErr,
        codeMelliErr: "کد ملی نمیتواند خالی باشد",
      });
    } else if (
      litePersonInput.codeMelli.length !== 10 ||
      !IRCheck.National.isNationalCodeValid(litePersonInput.codeMelli)
    ) {
      setLitePersonInputErr({
        ...litePersonInputErr,
        codeMelliErr: "لطفا کد ملی معتبر وارد کنید",
      });
    } else {
      setLitePersonInputErr({
        ...litePersonInputErr,
        codeMelliErr: "",
      });
      dispatch(toggleModalLoading(true));
      if (actionType === "ADD") {
        AxiosInterceptor.post("/LitePerson/Edit", {
          name: litePersonInput.name,
          family: litePersonInput.family,
          codeMelli: litePersonInput.codeMelli,
          image: litePersonInput.image,
          hasGozarname: litePersonInput.hasGozarname,
          gozarnameNumber: litePersonInput.gozarnameNumber,
          gozarnameMailNumber: litePersonInput.gozarnameMailNumber,
          gozarnameFile: litePersonInput.gozarnameFile,
          gozarnameDate: litePersonInput.gozarnameDate
            ? litePersonInput.gozarnameDate
            : null,
        }).then((response) => {
          if (response.data.status) {
            let litePersonId = response.data.eLitePersonId;
            setLitePersonInput({
              ...litePersonInput,
              eLitePersonId: litePersonId,
            });
            AxiosInterceptor.post("/CompanyPerson/Edit", {
              eCompanyId: personMembersModal.eCompanyId,
              eLitePersonId: litePersonId,
              type: personMembersModal.type === "directors" ? 0 : 1, //  0=heyatModire, 1=karkonan
              jobPosition: litePersonInput.jobPosition,
              status: litePersonInput.status,
            }).then((response) => {
              if (response.data.status) {
                swal({
                  icon: "success",
                  title: "موفقیت",
                  text: "فرد مورد نظر با موفقیت ثبت گردید.",
                  button: "متوجه شدم",
                });
                setPersonMembersModal({
                  ...personMembersModal,
                  addingPersonMode: false,
                });
                getAllPersons(
                  personMembersModal.eCompanyId,
                  personMembersModal.type
                );
              } else {
                swal({
                  icon: "error",
                  title: "خطا",
                  text: response.data.message,
                  button: "متوجه شدم",
                });
                dispatch(toggleModalLoading(false));
              }
            });
          } else {
            swal({
              icon: "error",
              title: "خطا",
              text: response.data.message,
              button: "متوجه شدم",
            });
            dispatch(toggleModalLoading(false));
          }
        });
      } else {
        AxiosInterceptor.post("/LitePerson/Edit", {
          eLitePersonId: litePersonInput.eLitePersonId,
          name: litePersonInput.name,
          family: litePersonInput.family,
          codeMelli: litePersonInput.codeMelli,
          image: litePersonInput.image,
          hasGozarname: litePersonInput.hasGozarname,
          gozarnameNumber: litePersonInput.gozarnameNumber,
          gozarnameMailNumber: litePersonInput.gozarnameMailNumber,
          gozarnameFile: litePersonInput.gozarnameFile,
          gozarnameDate: litePersonInput.gozarnameDate
            ? litePersonInput.gozarnameDate
            : null,
        }).then((response) => {
          if (response.data.status) {
            AxiosInterceptor.post("/CompanyPerson/Edit", {
              eCompanyPersonId: litePersonInput.eCompanyPersonId,
              eCompanyId: personMembersModal.eCompanyId,
              eLitePersonId: litePersonInput.eLitePersonId,
              type: personMembersModal.type === "directors" ? 0 : 1, //  0=heyatModire, 1=karkonan
              jobPosition: litePersonInput.jobPosition,
              status: litePersonInput.status,
            }).then((response) => {
              if (response.data.status) {
                swal({
                  icon: "success",
                  title: "موفقیت",
                  text: "فرد مورد نظر با موفقیت به روزرسانی گردید.",
                  button: "متوجه شدم",
                });
                setPersonMembersModal({
                  ...personMembersModal,
                  addingPersonMode: false,
                });
                getAllPersons(
                  personMembersModal.eCompanyId,
                  personMembersModal.type
                );
              } else {
                swal({
                  icon: "error",
                  title: "خطا",
                  text: response.data.message,
                  button: "متوجه شدم",
                });
                dispatch(toggleModalLoading(false));
              }
            });
          } else {
            swal({
              icon: "error",
              title: "خطا",
              text: response.data.message,
              button: "متوجه شدم",
            });
            dispatch(toggleModalLoading(false));
          }
        });
      }
    }
  };
  const deleteCompanyPerson = (eCompanyPersonId) => {
    document.getElementsByClassName("fade modal show")?.[0]?.removeAttribute("tabindex");
    let alertInput = document.createElement("div");
    ReactDOM.render(
      <Form.Control id="adminPassword" name="adminPassword" type="password" autoComplete="new-password" />,
      alertInput
    );
    let el = alertInput.firstChild;
    swal("برای حذف باید کلمه عبور ادمین را وارد نمایید.", {
      content: el,
      buttons: ["لغو", "حذف"],
    }).then((value) => {
      if (value) {
        AxiosInterceptor.post("/CompanyPerson/Delete", {
          id: eCompanyPersonId,
          password: el.value,
        }).then((response) => {
          if (response.data.status) {
            swal({
              icon: "success",
              title: "موفقیت",
              text: "فرد مورد نظر با موفقیت حذف گردید",
              button: "متوجه شدم",
            });
            getAllPersons(
              personMembersModal.eCompanyId,
              personMembersModal.type
            );
          } else {
            swal({
              icon: "error",
              title: "خطا",
              text: response.data.message,
              button: "متوجه شدم",
            });
          }
        });
      }
    }).then(() => {
      document.getElementsByClassName("fade modal show")?.[0]?.setAttribute("tabindex", "-1");
    });
  };
  /* ===================================== 
   =======================================
     ===================================== 
   */
  const fileChangeHandler = (e) => {
    let size = (e.target.files[0].size / 1024).toFixed(2);
    let fileName = e.target.files[0].name;
    if (size > 5000) {
      swal({
        icon: "error",
        title: "خطا",
        text: "حداکثر حجم مجاز 5 مگابایت است",
        button: "متوجه شدم",
      });
    } else if (fileName.includes(".exe")) {
      swal({
        icon: "error",
        title: "خطا",
        text: "فایل‌های اجرایی را نمیتوان آپلود کرد",
        button: "متوجه شدم",
      });
    } else {
      dispatch(toggleModalLoading(true));
      let reader = new FileReader();
      reader.onloadend = () => {
        AxiosInterceptor.post("/Setting/Upload", {
          file: reader.result,
          name: fileName,
        }).then((response) => {
          if (e.target.id === "companyImageInput") {
            setCompanyInputs({
              ...companyInputs,
              image: response.data.message,
            });
          } else if (e.target.id === "litePersonImageInput") {
            setLitePersonInput({
              ...litePersonInput,
              image: response.data.message,
            });
          } else {
            // gozarnameFileInput
            setLitePersonInput({
              ...litePersonInput,
              gozarnameFile: response.data.message,
            });
          }
          dispatch(toggleModalLoading(false));
        });
      };
      reader.readAsDataURL(e.target.files[0]);
    }
  };
  useEffect(() => {
    getAllCompanies();
    getStaticData();
  }, []);
  useEffect(() => {
    if (+reduxState.companiesCount > 0) {
      let arr = [];
      let groupsNumber =
        parseInt(reduxState.companiesCount) /
        parseInt(paginationFilter.docNumberPerPage);
      let i = 1;
      while (groupsNumber > 0) {
        groupsNumber--;
        arr.push(i);
        i++;
      }
      setPaginationFilter({
        ...paginationFilter,
        items: arr,
      });
    }
    // let wrapperHeight = document.querySelector(
    //   "#layoutSidenav_content"
    // ).clientHeight;
    // switch (paginationFilter.docNumberPerPage) {
    //   case "5":
    //     tableHeightRef.current.style.maxHeight =
    //       parseInt(wrapperHeight) - 100 + "px";
    //     break;
    //   case "10":
    //     tableHeightRef.current.style.maxHeight =
    //       parseInt(wrapperHeight) - 250 + "px";
    //     break;
    //   case "15":
    //     tableHeightRef.current.style.maxHeight =
    //       parseInt(wrapperHeight) - 300 + "px";
    //     break;
    //   default:
    //     break;
    // }
  }, [paginationFilter.docNumberPerPage, reduxState.companiesCount]);
  return (
    <Dashboard>
      <div className="companyList-wrapper p-4">
        <Row className="mb-2">
          <Col
            sm={12}
            lg={4}
            className="d-flex align-item-center justify-content-start"
          >
            <h5 className="m-0 set-column-middle">لیست شرکت ها</h5>
          </Col>
          <Col
            sm={12}
            lg={8}
            className="d-flex align-item-center justify-content-end"
          >
            <button
              className="btn btn-success mx-2 text-white"
              onClick={() => {
                setCompanyInputs(initialCompanyInputs);
                setCompanyModal({
                  show: true,
                  codeMelliIsOk: false,
                  codeMelliErr: "",
                });
              }}
            >
              افزودن شرکت جدید +
            </button>
          </Col>
        </Row>
        <hr />
        <Row
          ref={tableHeightRef}
          style={{
            overflowY: "auto",
          }}
        >
          <Table striped bordered hover responsive>
            <thead className="gradiantBg text-white text-center">
              <tr>
                <th scope="col" className="text-nowrap">
                  ردیف
                </th>
                <th scope="col" className="text-nowrap">
                  تصویر
                </th>
                <th scope="col" className="text-nowrap">
                  نام
                </th>
                <th scope="col" className="text-nowrap">
                  شناسه ملی
                </th>
                <th scope="col" className="text-nowrap">
                  شماره ثبت
                </th>
                <th scope="col" className="text-nowrap">
                  کد اقتصادی
                </th>
                <th scope="col" className="text-nowrap">
                  صلاحیت همکاری
                </th>
                <th scope="col" className="text-nowrap">
                  سمتا
                </th>
                <th scope="col" className="text-nowrap">
                  عملیات
                </th>
              </tr>
            </thead>
            <tbody className="text-center">
              {companyList.length > 0 ? (
                companyList?.map((company, index) => {
                  return (
                    <tr key={index} id={company.eCompanyId}>
                      <td className="text-nowrap">
                        {parseInt(paginationFilter.pointer) *
                          parseInt(paginationFilter.docNumberPerPage) +
                          (index +
                            1 -
                            parseInt(paginationFilter.docNumberPerPage))}
                      </td>
                      <td className="text-nowrap set-row-middle">
                        <div style={{ width: "40px", height: "40px" }}>
                          <Image
                            className="cpointer zoomable_avatar"
                            src={
                              company.image
                                ? IMAGEURLS + company.image
                                : defCompany
                            }
                            width="40px"
                            height="40px"
                          />
                        </div>
                      </td>
                      <td className="text-nowrap">{company.name}</td>
                      <td className="text-nowrap">{company.codeMelli}</td>
                      <td className="text-nowrap">{company.sabtNumber}</td>
                      <td className="text-nowrap">{company.eghtesadiCode}</td>
                      <td className="text-nowrap">
                        {/* 
                            دارد = 0,
                            ندارد = 1,
                            مشکوک_المعامله = 2 
                        */}
                        {company.salahiyateHamkariInt === 0 ? (
                          <OverlayTrigger
                            placement="top"
                            delay={{ show: 250, hide: 400 }}
                            overlay={
                              <Tooltip>
                                {company.salahiyateHamkari.replaceAll("_", " ")}
                              </Tooltip>
                            }
                          >
                            <span className="text-success cpointer">
                              <CheckLg size="1.3em" />
                            </span>
                          </OverlayTrigger>
                        ) : company.salahiyateHamkariInt === 1 ? (
                          <OverlayTrigger
                            placement="top"
                            delay={{ show: 250, hide: 400 }}
                            overlay={
                              <Tooltip>
                                {company.salahiyateHamkari.replaceAll("_", " ")}
                              </Tooltip>
                            }
                          >
                            <span className="text-danger cpointer">
                              <XLg size="1.3em" />
                            </span>
                          </OverlayTrigger>
                        ) : (
                          <OverlayTrigger
                            placement="top"
                            delay={{ show: 250, hide: 400 }}
                            overlay={
                              <Tooltip>
                                {company.salahiyateHamkari.replaceAll("_", " ")}
                              </Tooltip>
                            }
                          >
                            <span className="text-orange cpointer">
                              <Bricks size="1.3em" />
                            </span>
                          </OverlayTrigger>
                        )}
                      </td>
                      <td className="text-nowrap">
                        {company.samta ? (
                          <span className="text-success">
                            <CheckLg size="1.3em" />
                          </span>
                        ) : (
                          <span className="text-danger">
                            <XLg size="1.3em" />
                          </span>
                        )}
                      </td>
                      <td>
                        <div className="set-row-middle">
                          <span
                            className="mx-2 cpointer text-nowrap"
                            onClick={() => {
                              navigate(`/ViewCompany/${company.eCompanyId}`);
                            }}
                          >
                            <i className="ms-1">
                              <EyeFill
                                className="text-success"
                                size={"1.2rem"}
                              />
                            </i>
                            در یک نگاه
                          </span>
                          <span
                            className="mx-2 cpointer text-nowrap"
                            onClick={() => {
                              setPersonMembersModal({
                                ...personMembersModal,
                                show: true,
                                type: "directors",
                                eCompanyId: company.eCompanyId,
                                addingPersonMode: false,
                              });
                              getAllPersons(company.eCompanyId, "directors");
                            }}
                          >
                            <i className="ms-1">
                              <InfoSquareFill
                                className="text-info"
                                size={"1.2rem"}
                              />
                            </i>
                            اعضای هیئت مدیره
                          </span>
                          <span
                            className="mx-2 cpointer text-nowrap"
                            onClick={() => {
                              setPersonMembersModal({
                                ...personMembersModal,
                                show: true,
                                type: "personel",
                                eCompanyId: company.eCompanyId,
                                addingPersonMode: false,
                              });
                              getAllPersons(company.eCompanyId, "personel");
                            }}
                          >
                            <i className="ms-1">
                              <InfoSquareFill
                                className="text-info"
                                size={"1.2rem"}
                              />
                            </i>
                            لیست افراد
                          </span>
                          <span
                            className="mx-1 cpointer text-nowrap"
                            onClick={() => {
                              editCompany(company);
                            }}
                          >
                            <i className="ms-1">
                              <PencilSquare
                                className="text-warning"
                                size={"1.2rem"}
                              />
                            </i>
                            ویرایش
                          </span>
                          <span
                            className="mx-1 cpointer text-nowrap"
                            onClick={() => {
                              deleteCompany(company.eCompanyId);
                            }}
                          >
                            <i className="ms-1">
                              <Trash className="text-danger" size={"1.2rem"} />
                            </i>
                            حذف
                          </span>
                        </div>
                      </td>
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td colSpan={9}>لیست شرکت ها خالی میباشد</td>
                </tr>
              )}
            </tbody>
          </Table>
        </Row>
        <Row>
          <Col sm={12} className="px-3 py-3 set-row-middle position-relative">
            <div
              className="input-wrapper flex-nowrap"
              style={{ position: "absolute", right: "5px" }}
            >
              <Form.Group className="set-row-middle">
                <InputGroup.Text>برو به صفحه</InputGroup.Text>
                <FormControl
                  name="goTo"
                  type="number"
                  style={{ maxWidth: "70px" }}
                  value={paginationFilter.goTo}
                  onChange={(e) => {
                    setPaginationFilter({
                      ...paginationFilter,
                      goTo: e.target.value,
                    });
                  }}
                />
                <button
                  className="btn gradiantBg"
                  onClick={() => {
                    if (paginationFilter.goTo !== "") {
                      getThisPageCompanies(parseInt(paginationFilter.goTo));
                    }
                  }}
                >
                  <ArrowLeftCircleFill size="1.3em" color="#fff" />
                </button>
              </Form.Group>
            </div>
            <ul
              id="paginationList"
              className="m-0 p-0 list-unstyled set-row-middle flex-wrap"
            >
              <li
                className="ms-1 cpointer"
                onClick={() => {
                  if (paginationFilter.pointer !== paginationFilter.items[0]) {
                    getThisPageCompanies(paginationFilter.pointer - 1);
                  }
                }}
              >
                <ArrowRight size="1.3em" />
              </li>
              {paginationFilter.pointer !== 1 && (
                <li
                  className="paginationItems"
                  onClick={() => {
                    getThisPageCompanies(1);
                  }}
                >
                  1
                </li>
              )}
              {paginationFilter.pointer > 2 && (
                <li>
                  <strong>.....</strong>
                </li>
              )}
              {paginationFilter.items?.map((pa, index) => {
                return index + 1 === paginationFilter.pointer ||
                  (index <= paginationFilter.pointer + 1 &&
                    index + 1 >= paginationFilter.pointer) ||
                  index + 1 ===
                  paginationFilter.items[
                  paginationFilter.items.length - 1
                  ] ? (
                  <li
                    key={index}
                    className={`paginationItems ${index + 1 === paginationFilter.pointer
                      ? "paginationItemActive"
                      : ""
                      }`}
                    onClick={() => {
                      getThisPageCompanies(pa);
                    }}
                  >
                    {pa}
                  </li>
                ) : index + 1 ===
                  paginationFilter.items[paginationFilter.items.length - 2] ? (
                  <>
                    <li>
                      <strong>.....</strong>
                    </li>
                    <li
                      key={index}
                      className={`paginationItems ${index + 1 === paginationFilter.pointer
                        ? "paginationItemActive"
                        : ""
                        }`}
                      onClick={() => {
                        getThisPageCompanies(pa);
                      }}
                    >
                      {pa}
                    </li>
                  </>
                ) : (
                  ""
                );
              })}

              <li
                className="me-1 cpointer"
                onClick={() => {
                  if (
                    paginationFilter.pointer !==
                    paginationFilter.items[paginationFilter.items.length - 1]
                  ) {
                    getThisPageCompanies(paginationFilter.pointer + 1);
                  }
                }}
              >
                <ArrowLeft size="1.3em" />
              </li>
            </ul>
            <div
              className="input-wrapper flex-nowrap"
              style={{ position: "absolute", left: "5px" }}
            >
              <Form.Group className="set-row-middle">
                <InputGroup.Text>تعداد سند در هر صفحه</InputGroup.Text>
                <Form.Select
                  value={paginationFilter.docNumberPerPage}
                  name="docNumberPerPage"
                  id="docNumberPerPage"
                  onChange={(e) => {
                    let myTake = e.target.value;
                    dispatch(toggleLoading(true));
                    AxiosInterceptor.post("/Company/List", {
                      page: 1,
                      pageSize: +myTake,
                      orderBy: "",
                      filter: "",
                    }).then((response) => {
                      if (response.data.status) {
                        setCompanyInputs(response.data.companies.data);
                        dispatch(
                          setCompaniesCount(response.data.companies.count)
                        );
                        setPaginationFilter((prevState) => ({
                          ...prevState,
                          docNumberPerPage: myTake,
                          pointer: 1,
                        }));
                      } else {
                        swal({
                          icon: "error",
                          title: "خطا",
                          text: response.data.message,
                          button: "متوجه شدم",
                        });
                      }
                      dispatch(toggleLoading(false));
                    });
                  }}
                >
                  <option value="5">5</option>
                  <option value="10">10</option>
                  <option value="15">15</option>
                </Form.Select>
              </Form.Group>
            </div>
          </Col>
        </Row>
      </div>
      <Portal show={companyModal.show}>
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter">
            افزودن شرکت جدید
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            {companyModal.codeMelliIsOk ? (
              <>
                <Col sm={12} lg={3} className="p-3 set-column-middle">
                  <div className="profileImage-wrapper set-column-middle">
                    <img
                      src={
                        companyInputs.image
                          ? IMAGEURLS + companyInputs.image
                          : defCompany
                      }
                      alt="profile"
                    />
                    <input
                      type="file"
                      name="image"
                      className="d-none"
                      id="companyImageInput"
                      onChange={fileChangeHandler}
                    />
                    <button
                      className="btn btn-primary"
                      style={{
                        width: "50px",
                        height: "50px",
                        position: "absolute",
                        top: "-20px",
                        right: "-20px",
                        borderRadius: "50%",
                      }}
                      onClick={() => {
                        document.querySelector("#companyImageInput").click();
                      }}
                    >
                      <PencilSquare size="1.3em" />
                    </button>
                  </div>
                  {companyInputs.salahiyateHamkariInt !== undefined && (
                    <div id="salahiyat_title" className="mt-2">
                      <h2 className="text-center">صلاحیت همکاری</h2>
                      <h3 className="text-center">
                        {/* 
                            دارد = 0,
                            ندارد = 1,
                            مشکوک_المعامله = 2 
                        */}
                        {companyInputs.salahiyateHamkariInt === 0 ? (
                          <strong
                            className="text-success"
                            style={{ marginRight: "-25px" }}
                          >
                            <CheckLg size="1.3em" />
                            {companyInputs.salahiyateHamkari.replaceAll(
                              "_",
                              " "
                            )}
                          </strong>
                        ) : companyInputs.salahiyateHamkariInt === 1 ? (
                          <strong
                            className="text-danger"
                            style={{ marginRight: "-25px" }}
                          >
                            <XLg size="1.3em" />
                            {companyInputs.salahiyateHamkari.replaceAll(
                              "_",
                              " "
                            )}
                          </strong>
                        ) : (
                          <strong
                            className="text-danger"
                            style={{ marginRight: "-25px" }}
                          >
                            <Bricks size="1.3em" />
                            {companyInputs.salahiyateHamkari.replaceAll(
                              "_",
                              " "
                            )}
                          </strong>
                        )}
                      </h3>
                    </div>
                  )}
                </Col>
                <Col sm={12} lg={9}>
                  <Row>
                    <Col sm={12} lg={4}>
                      <Form.Group className="input-wrapper">
                        <InputGroup.Text>نام</InputGroup.Text>
                        <FormControl
                          name="name"
                          type="text"
                          value={companyInputs.name}
                          onChange={companyOnChangeHandle}
                        />
                      </Form.Group>
                    </Col>
                    <Col sm={12} lg={4}>
                      <Form.Group className="input-wrapper">
                        <InputGroup.Text>شناسه ملی</InputGroup.Text>
                        {canChangeCompanyCodeMelli ? (
                          <FormControl
                            name="codeMelli"
                            type="text"
                            value={companyInputs.codeMelli}
                            className={
                              companyInputsErr.codeMelliErr ? "errorBorder" : ""
                            }
                            onChange={companyOnChangeHandle}
                          />
                        ) : (
                          <div className="position-relative">
                            <FormControl
                              name="codeMelli"
                              type="text"
                              value={companyInputs.codeMelli}
                              disabled
                              style={{
                                backgroundColor: "#e9ecef",
                              }}
                            />
                            <i
                              className="set-row-middle position-absolute bg-warning cpointer"
                              style={{
                                width: "15%",
                                height: "63%",
                                left: "5px",
                                top: "7px",
                                padding: "1px",
                                borderRadius: "50%",
                              }}
                              onClick={getAccessToChangeCodeMelli}
                            >
                              <PencilSquare
                                className="text-white"
                                size={".85rem"}
                              />
                            </i>
                          </div>
                        )}
                      </Form.Group>
                      <p className="error text-center">
                        {companyInputsErr.codeMelliErr}
                      </p>
                    </Col>
                    <Col sm={12} lg={4}>
                      <Form.Group className="input-wrapper">
                        <InputGroup.Text>کد اقتصادی</InputGroup.Text>
                        <FormControl
                          name="eghtesadiCode"
                          type="text"
                          value={companyInputs.eghtesadiCode}
                          className={
                            companyInputsErr.eghtesadiCodeErr
                              ? "errorBorder"
                              : ""
                          }
                          onChange={companyOnChangeHandle}
                        />
                      </Form.Group>
                      <p className="error text-center">
                        {companyInputsErr.eghtesadiCodeErr}
                      </p>
                    </Col>
                    <Col sm={12} lg={4}>
                      <Form.Group className="input-wrapper">
                        <InputGroup.Text>تلفن</InputGroup.Text>
                        <FormControl
                          name="phone"
                          type="text"
                          value={companyInputs.phone}
                          onChange={companyOnChangeHandle}
                        />
                      </Form.Group>
                    </Col>
                    <Col sm={12} lg={4}>
                      <Form.Group className="input-wrapper">
                        <InputGroup.Text>شماره ثبت</InputGroup.Text>
                        <FormControl
                          name="sabtNumber"
                          type="text"
                          value={companyInputs.sabtNumber}
                          className={
                            companyInputsErr.sabtNumberErr ? "errorBorder" : ""
                          }
                          onChange={companyOnChangeHandle}
                        />
                      </Form.Group>
                      <p className="error text-center">
                        {companyInputsErr.sabtNumberErr}
                      </p>
                    </Col>
                    <Col sm={12} lg={4} className="input-wrapper">
                      <EnumSelector
                        data={staticDatas}
                        enumKey="PHozeFaaliyat"
                        name="hozeFaaliyat"
                        title="حوزه فعالیت"
                        value={companyInputs.hozeFaaliyat}
                        onChange={companyOnChangeHandle}
                      />
                    </Col>
                    <Col sm={12} lg={4} className="input-wrapper">
                      <EnumSelector
                        data={staticDatas}
                        enumKey="PHamkariType"
                        name="hamkariType"
                        title="نوع همکاری"
                        value={companyInputs.hamkariType}
                        onChange={companyOnChangeHandle}
                      />
                    </Col>
                    {/* <Col sm={12} lg={4} className="input-wrapper">
                      <Form.Group
                        className="set-row-middle"
                        controlId="hasSooePishine"
                      >
                        <InputGroup.Text>سمتا</InputGroup.Text>
                        <Form.Select
                          value={}
                          name="samta"
                          onChange={companyOnChangeHandle}
                        >
                          <option value={false}>ندارد</option>
                          <option value={true}>دارد</option>
                        </Form.Select>
                      </Form.Group>
                    </Col> */}
                    <Col sm={12} lg={4} className="input-wrapper">
                      <div className="checkbox_wrapper set-row-middle">
                        <h5 className="ms-2 mb-0">سمتا ؟</h5>
                        <input
                          type="checkbox"
                          id="samta"
                          checked={companyInputs.samta}
                          onChange={(e) => {
                            setCompanyInputs({
                              ...companyInputs,
                              samta: e.target.checked,
                            });
                          }}
                        />
                        <label htmlFor="samta" className="check-box"></label>
                      </div>
                    </Col>

                    <Col sm={12}>
                      <Form.Group className="input-wrapper">
                        <InputGroup.Text>آدرس</InputGroup.Text>
                        <FormControl
                          name="address"
                          type="text"
                          value={companyInputs.address}
                          onChange={companyOnChangeHandle}
                        />
                      </Form.Group>
                    </Col>
                    <Col sm={12}>
                      {companyInputs.eCompanyId ? (
                        <button
                          className="btn btn-success w-100"
                          onClick={() => ADDorUPDATEcompany("UPDATE")}
                        >
                          به روز رسانی اطلاعات
                        </button>
                      ) : (
                        <button
                          className="btn btn-success w-100"
                          onClick={() => ADDorUPDATEcompany("ADD")}
                        >
                          ثبت اطلاعات
                        </button>
                      )}
                    </Col>
                  </Row>
                </Col>
              </>
            ) : (
              <>
                <Col sm={12} className="text-center mb-2">
                  <h5>شناسه ملی شرکت مورد نظر را وارد نمایید</h5>
                </Col>
                <Col sm={12} className="set-row-middle my-1">
                  <Form.Group className="input-wrapper">
                    <InputGroup.Text>شناسه ملی</InputGroup.Text>
                    <FormControl
                      name="codeMelli"
                      id="codeMelli"
                      type="text"
                      className={
                        companyInputsErr.codeMelliErr ? "errorBorder" : ""
                      }
                      value={companyInputs.codeMelli}
                      onChange={(e) => {
                        if (e.target.value.length < 12) {
                          setCompanyInputs({
                            ...companyInputs,
                            codeMelli: e.target.value,
                          });
                        }
                      }}
                    />
                  </Form.Group>
                </Col>
                <p className="error text-center">
                  {companyInputsErr.codeMelliErr}
                </p>
                <Col sm={12} className="text-center mt-2">
                  <button
                    className="btn btn-success mx-2 text-white"
                    onClick={confirmcodeMelli}
                  >
                    بررسی
                  </button>
                </Col>
              </>
            )}
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn btn-danger"
            onClick={() => {
              setCompanyModal({
                show: false,
                codeMelliIsOk: false,
                codeMelliErr: "",
              });
            }}
          >
            بستن
          </button>
        </Modal.Footer>
      </Portal>
      <Portal show={personMembersModal.show}>
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter">
            {personMembersModal.type === "directors"
              ? "اعضای هیئت مدیره"
              : "لیست افراد"}
          </Modal.Title>
          {personMembersModal.addingPersonMode ? (
            <button
              className="btn btn-warning"
              onClick={() =>
                setPersonMembersModal({
                  ...personMembersModal,
                  addingPersonMode: false,
                })
              }
            >
              بازگشت به لیست
            </button>
          ) : (
            <button
              className="btn btn-success"
              onClick={() => {
                setPersonMembersModal({
                  ...personMembersModal,
                  addingPersonMode: true,
                });
                setLitePersonInput({
                  ...litePersonInput,
                  eLitePersonId: "",
                  name: "",
                  family: "",
                  codeMelli: "",
                  image: "",
                  jobPosition: 0,
                  status: true,
                });
                setLitePersonInputErr({
                  codeMelliErr: "",
                });
              }}
            >
              {personMembersModal.type === "directors"
                ? "افزودن هیئت مدیره جدید +"
                : "افزودن فرد جدید +"}
            </button>
          )}
        </Modal.Header>
        <Modal.Body>
          <Row>
            {personMembersModal.addingPersonMode ? (
              <>
                <Col sm={12} lg={3} className="p-3 set-column-middle">
                  <div className="profileImage-wrapper set-column-middle">
                    <img
                      src={
                        litePersonInput.image
                          ? IMAGEURLS + litePersonInput.image
                          : defProfile
                      }
                      alt="profile"
                    />
                    <input
                      type="file"
                      name="image"
                      className="d-none"
                      id="litePersonImageInput"
                      onChange={fileChangeHandler}
                    />
                    <button
                      className="btn btn-primary"
                      style={{
                        width: "50px",
                        height: "50px",
                        position: "absolute",
                        top: "-20px",
                        right: "-20px",
                        borderRadius: "50%",
                      }}
                      onClick={() => {
                        document.querySelector("#litePersonImageInput").click();
                      }}
                    >
                      <PencilSquare size="1.3em" />
                    </button>
                  </div>
                </Col>
                <Col sm={12} lg={9}>
                  <Row>
                    <Col sm={12} lg={4}>
                      <Form.Group className="input-wrapper">
                        <InputGroup.Text>نام</InputGroup.Text>
                        <FormControl
                          name="name"
                          type="text"
                          value={litePersonInput.name}
                          onChange={litePersonOnChangeHandle}
                        />
                      </Form.Group>
                    </Col>
                    <Col sm={12} lg={4}>
                      <Form.Group className="input-wrapper">
                        <InputGroup.Text>نام خانوادگی</InputGroup.Text>
                        <FormControl
                          name="family"
                          type="text"
                          value={litePersonInput.family}
                          onChange={litePersonOnChangeHandle}
                        />
                      </Form.Group>
                    </Col>
                    <Col sm={12} lg={4}>
                      <Form.Group className="input-wrapper">
                        <InputGroup.Text>کد ملی</InputGroup.Text>
                        <FormControl
                          name="codeMelli"
                          type="text"
                          className={
                            litePersonInputErr.codeMelliErr ? "errorBorder" : ""
                          }
                          value={litePersonInput.codeMelli}
                          onChange={litePersonOnChangeHandle}
                        />
                      </Form.Group>
                      <p className="error text-center">
                        {litePersonInputErr.codeMelliErr}
                      </p>
                    </Col>
                    {personMembersModal.type === "directors" ? (
                      <>
                        <Col sm={12} lg={4} className="input-wrapper">
                          <Form.Group
                            className="set-row-middle"
                            controlId="jobPosition"
                          >
                            <InputGroup.Text>سمت</InputGroup.Text>
                            <Form.Select
                              value={litePersonInput.jobPosition}
                              name="jobPosition"
                              onChange={litePersonOnChangeHandle}
                            >
                              <option value={0}>انتخاب کنید</option>
                              {staticDatas.PJobPositions?.map((op, index) => {
                                return (
                                  <option
                                    value={op.key}
                                    key={index}
                                    id={op.key}
                                  >
                                    {op.value}
                                  </option>
                                );
                              })}
                            </Form.Select>
                          </Form.Group>
                        </Col>
                        <Col sm={12} lg={4} className="input-wrapper">
                          <Form.Group
                            className="set-row-middle"
                            controlId="hasSooePishine"
                          >
                            <InputGroup.Text>وضعیت</InputGroup.Text>
                            <Form.Select
                              value={litePersonInput.status}
                              name="status"
                              onChange={litePersonOnChangeHandle}
                            >
                              <option value={true}>فعلی</option>
                              <option value={false}>سابق</option>
                            </Form.Select>
                          </Form.Group>
                        </Col>
                      </>
                    ) : (
                      <Col sm={12} lg={4} className="input-wrapper">
                        <Form.Group
                          className="set-row-middle"
                          controlId="jobPosition"
                        >
                          <InputGroup.Text>سمت</InputGroup.Text>
                          <Form.Select
                            value={litePersonInput.jobPosition}
                            name="jobPosition"
                            onChange={litePersonOnChangeHandle}
                          >
                            <option value={0}>انتخاب کنید</option>
                            {staticDatas.PJobPositionsPersonel?.map(
                              (op, index) => {
                                return (
                                  <option
                                    value={op.key}
                                    key={index}
                                    id={op.key}
                                  >
                                    {op.value}
                                  </option>
                                );
                              }
                            )}
                          </Form.Select>
                        </Form.Group>
                      </Col>
                    )}
                    <Col sm={12} lg={4} className="input-wrapper">
                      <div className="checkbox_wrapper set-row-middle">
                        <h5 className="ms-2 mb-0">گذرنامه ؟</h5>
                        <input
                          type="checkbox"
                          id="hasGozarname"
                          checked={litePersonInput.hasGozarname}
                          onChange={(e) => {
                            if (!e.target.checked) {
                              setLitePersonInput({
                                ...litePersonInput,
                                hasGozarname: e.target.checked,
                                gozarnameNumber: "",
                                gozarnameMailNumber: "",
                                gozarnameFile: "",
                                gozarnameDate: "",
                              });
                            } else {
                              setLitePersonInput({
                                ...litePersonInput,
                                hasGozarname: e.target.checked,
                              });
                            }
                          }}
                        />
                        <label
                          htmlFor="hasGozarname"
                          className="check-box"
                        ></label>
                      </div>
                    </Col>
                  </Row>
                  {litePersonInput.hasGozarname && (
                    <Row>
                      <Col sm={12} lg={4}>
                        <Form.Group className="input-wrapper">
                          <InputGroup.Text>شماره گذرنامه</InputGroup.Text>
                          <FormControl
                            name="gozarnameNumber"
                            type="text"
                            value={litePersonInput.gozarnameNumber}
                            onChange={litePersonOnChangeHandle}
                          />
                        </Form.Group>
                      </Col>
                      <Col sm={12} lg={4}>
                        <Form.Group className="input-wrapper">
                          <InputGroup.Text>شماره نامه</InputGroup.Text>
                          <FormControl
                            name="gozarnameMailNumber"
                            type="text"
                            value={litePersonInput.gozarnameMailNumber}
                            onChange={litePersonOnChangeHandle}
                          />
                        </Form.Group>
                      </Col>
                      <Col sm={12} lg={4} className="input-wrapper">
                        <InputGroup className="flex-nowrap">
                          <InputGroup.Text>تاریخ نامه</InputGroup.Text>
                          <DateInput
                            name="gozarnameDate"
                            onChange={(e) => {
                              setLitePersonInput({
                                ...litePersonInput,
                                [e.target.name]: e.target.value.split("T")[0],
                              });
                            }}
                            autoOk={true}
                            value={
                              litePersonInput.gozarnameDate
                                ? litePersonInput.gozarnameDate
                                : ""
                            }
                          />
                        </InputGroup>
                      </Col>
                      <Col sm={12} lg={6} className="input-wrapper">
                        <InputGroup>
                          <InputGroup.Text>فایل گذرنامه</InputGroup.Text>
                          {litePersonInput.gozarnameFile ? (
                            <>
                              <a
                                className="btn btn-success text-white"
                                href={IMAGEURLS + litePersonInput.gozarnameFile}
                                target="_blank"
                                rel="noreferrer"
                              >
                                <Eye size="1.2em" className="ms-1" />
                                مشاهده
                              </a>
                              <button
                                className="btn btn-danger text-white"
                                onClick={() => {
                                  setLitePersonInput({
                                    ...litePersonInput,
                                    gozarnameFile: "",
                                  });
                                }}
                              >
                                <Trash2 size="1.2em" className="ms-1" />
                                حذف
                              </button>
                            </>
                          ) : (
                            <>
                              <input
                                type="file"
                                name="image"
                                className="d-none"
                                id="gozarnameFileInput"
                                onChange={fileChangeHandler}
                              />
                              <button
                                className="btn btn-info text-white"
                                onClick={() => {
                                  document
                                    .getElementById("gozarnameFileInput")
                                    .click();
                                }}
                              >
                                <CloudArrowUpFill
                                  size="1.2em"
                                  className="ms-1"
                                />{" "}
                                بارگذاری
                              </button>
                            </>
                          )}
                        </InputGroup>
                      </Col>
                    </Row>
                  )}
                  <Row>
                    <Col sm={12} lg={12}>
                      {litePersonInput.eLitePersonId ? (
                        <button
                          className="btn btn-success w-100"
                          onClick={() => {
                            ADDorUPDATECompanyPerson("UPDATE");
                          }}
                        >
                          به روز رسانی
                        </button>
                      ) : (
                        <button
                          className="btn btn-success w-100"
                          onClick={() => {
                            ADDorUPDATECompanyPerson("ADD");
                          }}
                        >
                          ثبت
                        </button>
                      )}
                    </Col>
                  </Row>
                </Col>
              </>
            ) : (
              <>
                <Table striped bordered hover responsive>
                  <thead className="gradiantBg text-white text-center">
                    <tr>
                      <th scope="col" className="text-nowrap">
                        ردیف
                      </th>
                      <th scope="col" className="text-nowrap">
                        تصویر
                      </th>
                      <th scope="col" className="text-nowrap">
                        نام
                      </th>
                      <th scope="col" className="text-nowrap">
                        نام خانوادگی
                      </th>
                      <th scope="col" className="text-nowrap">
                        کد ملی
                      </th>
                      <th scope="col" className="text-nowrap">
                        صلاحیت همکاری
                      </th>
                      <th scope="col" className="text-nowrap">
                        سمت
                      </th>
                      {personMembersModal.type === "directors" && (
                        <>
                          <th scope="col" className="text-nowrap">
                            وضعیت
                          </th>
                        </>
                      )}
                      <th scope="col" className="text-nowrap">
                        گذرنامه
                      </th>
                      <th scope="col" className="text-nowrap">
                        عملیات
                      </th>
                    </tr>
                  </thead>
                  <tbody className="text-center">
                    {personMembersModal.list.length > 0 ? (
                      personMembersModal.list.map((cp, index) => {
                        return (
                          <tr key={index} id={cp.eCompanyPersonId}>
                            <td className="text-nowrap">{index + 1}</td>
                            <td className="text-nowrap">
                              <div style={{ width: "40px", height: "40px" }}>
                                <Image
                                  className="cpointer zoomable_avatar"
                                  src={
                                    cp.eLitePerson.image
                                      ? IMAGEURLS + cp.eLitePerson.image
                                      : defProfile
                                  }
                                  width="40px"
                                  height="40px"
                                />
                              </div>
                            </td>
                            <td className="text-nowrap">
                              {cp.eLitePerson.name}
                            </td>
                            <td className="text-nowrap">
                              {cp.eLitePerson.family}
                            </td>
                            <td className="text-nowrap">
                              {cp.eLitePerson.codeMelli}
                            </td>
                            <td className="text-nowrap">
                              {/* 
                                دارد = 0,
                                ندارد = 1,
                                موقت = 2,
                                شناسایی_جعل_سند = 3,
                              */}
                              {cp.eLitePerson.salahiyateHamkariInt === 0 ||
                                cp.eLitePerson.salahiyateHamkariInt === 2 ? (
                                <OverlayTrigger
                                  placement="top"
                                  delay={{ show: 250, hide: 400 }}
                                  overlay={
                                    <Tooltip>
                                      {cp.eLitePerson.salahiyateHamkari.replaceAll(
                                        "_",
                                        " "
                                      )}
                                    </Tooltip>
                                  }
                                >
                                  <span className="text-success cpointer">
                                    <CheckLg size="1.3em" />
                                  </span>
                                </OverlayTrigger>
                              ) : (
                                <OverlayTrigger
                                  placement="top"
                                  delay={{ show: 250, hide: 400 }}
                                  overlay={
                                    <Tooltip>
                                      {cp.eLitePerson.salahiyateHamkari.replaceAll(
                                        "_",
                                        " "
                                      )}
                                    </Tooltip>
                                  }
                                >
                                  <span className="text-danger cpointer">
                                    <XLg size="1.3em" />
                                  </span>
                                </OverlayTrigger>
                              )}
                            </td>
                            <td className="text-nowrap">
                              {cp.jobPositionDisplay}
                            </td>
                            {personMembersModal.type === "directors" && (
                              <>
                                <td className="text-nowrap">
                                  {cp.status ? "فعلی" : "سابق"}
                                </td>
                              </>
                            )}
                            <td className="text-nowrap">
                              {cp.eLitePerson.hasGozarname ? (
                                <span className="text-success">
                                  <CheckLg size="1.3em" />
                                </span>
                              ) : (
                                <span className="text-danger">
                                  <XLg size="1.3em" />
                                </span>
                              )}
                            </td>
                            <td>
                              <div className="set-row-middle">
                                <span
                                  className="mx-2 cpointer"
                                  onClick={() => editCompanyPerson(cp)}
                                >
                                  <i className="ms-1">
                                    <PencilSquare
                                      className="text-warning"
                                      size={"1.2rem"}
                                    />
                                  </i>
                                  ویرایش
                                </span>
                                <span
                                  className="mx-2 cpointer"
                                  onClick={() => {
                                    deleteCompanyPerson(cp.eCompanyPersonId);
                                  }}
                                >
                                  <i className="ms-1">
                                    <Trash
                                      className="text-danger"
                                      size={"1.2rem"}
                                    />
                                  </i>
                                  حذف
                                </span>
                              </div>
                            </td>
                          </tr>
                        );
                      })
                    ) : (
                      <tr>
                        <td colSpan={9}>لیست افراد خالی میباشد</td>
                      </tr>
                    )}
                  </tbody>
                </Table>
              </>
            )}
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn btn-danger"
            onClick={() =>
              setPersonMembersModal({
                ...personMembersModal,
                show: false,
              })
            }
          >
            بستن
          </button>
        </Modal.Footer>
      </Portal>
    </Dashboard>
  );
}

export default CompanyList;
