import React, { useEffect, useState, useRef, useCallback } from "react";
import ReactDOM from "react-dom";
import "./Personel.scss";
import {
  Table,
  Row,
  Col,
  Modal,
  InputGroup,
  FormControl,
  Form,
  Tabs,
  Tab,
  Image,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import {
  ArrowLeftCircleFill,
  PencilSquare,
  Trash,
  ArrowLeft,
  ArrowRight,
  InfoSquareFill,
  CheckLg,
  XLg,
  ExclamationDiamondFill,
  Camera,
  CardImage,
  Eye,
  Trash2,
  CloudArrowUpFill,
} from "react-bootstrap-icons";
import Dashboard from "../../components/Dashboard/Dashboard";
import AxiosInterceptor from "../../utils/AxiosInterceptor";
import IRCheck from "ircheck";
import swal from "sweetalert";
import { useSelector, useDispatch } from "react-redux";
import {
  toggleLoading,
  setPersonelCount,
  toggleModalLoading,
} from "../../redux/actions";
import { DateInput } from "react-hichestan-datetimepicker";
import Portal from "../../components/Portal/Portal";
import defProfile from "../../assets/images/defProfile.jpg";
import { IMAGEURLS } from "../../utils/Constants";
import DocItem from "../../pages/DocManagement/DocItem";
import EnumSelector from "../../components/EnumSelector/EnumSelector";
import EnumSelectorHasParent from "../../components/EnumSelector/EnumSelectorHasParent";
import Webcam from "react-webcam";

function Personel() {
  const reduxState = useSelector((state) => state);
  const dispatch = useDispatch();
  const tableHeightRef = useRef(null);
  const [staticDatas, setStaticDatas] = useState({});
  const [paginationFilter, setPaginationFilter] = useState({
    pointer: 1,
    items: [],
    docNumberPerPage: "15",
    goTo: "",
  });
  const [docTypes, setDocTypes] = useState({
    amniatiList: [],
    nezaratiList: [],
    maktabiList: [],
    edariList: [],
  });
  const [personDoc, setPersonDoc] = useState({
    eDocId: "",
    image: "",
  });
  const [personDocList, setPersonDocList] = useState({
    amniatiList: [],
    nezaratiList: [],
    maktabiList: [],
    edariList: [],
  });
  /* ===================================== 
  ================== GET DATA =================== 
    ===================================== 
  */
  const getAllStaticDocs = () => {
    AxiosInterceptor.post("/Doc/List").then((response) => {
      if (response.data.status) {
        let amniatiList = [],
          nezaratiList = [],
          maktabiList = [],
          edariList = [];
        response.data.items.forEach((item) => {
          switch (item.type) {
            case "Amniyati":
              amniatiList.push(item);
              break;
            case "Nezarati":
              nezaratiList.push(item);
              break;
            case "Maktabi":
              maktabiList.push(item);
              break;
            case "Edari":
              edariList.push(item);
              break;
            // case "Edu":

            //   break;
            default:
              break;
          }
        });
        setDocTypes({
          amniatiList: amniatiList,
          nezaratiList: nezaratiList,
          maktabiList: maktabiList,
          edariList: edariList,
        });
      } else {
        swal({
          icon: "error",
          title: "خطا",
          text: response.data.message,
          button: "متوجه شدم",
        });
      }
    });
  };
  const getStaticData = () => {
    AxiosInterceptor.post("/Setting/List", {
      page: 1,
      pageSize: 100,
      orderBy: "",
      filter: "",
    }).then((response) => {
      if (response.data.status) {
        let obj = {};
        response.data.settings.data.forEach((item) => {
          obj[item.key] = JSON.parse(item.value);
        });
        setStaticDatas(obj);
      } else {
        swal({
          icon: "error",
          title: "خطا",
          text: response.data.message,
          button: "متوجه شدم",
        });
      }
    });
  };
  const getAllPersonels = () => {
    dispatch(toggleLoading(true));
    AxiosInterceptor.post("/Person/List", {
      page: 1,
      pageSize: +paginationFilter.docNumberPerPage,
      orderBy: "",
      filter: "",
    }).then((response) => {
      if (response.data.status) {
        setPersonsList(response.data.persons.data);
        dispatch(setPersonelCount(response.data.persons.count));
      } else {
        swal({
          icon: "error",
          title: "خطا",
          text: response.data.message,
          button: "متوجه شدم",
        });
      }
      dispatch(toggleLoading(false));
    });
  };
  const getThisPagePersonels = (page) => {
    dispatch(toggleLoading(true));
    AxiosInterceptor.post("/Person/List", {
      page: page,
      pageSize: paginationFilter.docNumberPerPage,
      orderBy: "",
      filter: "",
    }).then((response) => {
      if (response.data.status) {
        setPersonsList(response.data.persons.data);
        dispatch(setPersonelCount(response.data.persons.count));
        setPaginationFilter((prevState) => ({
          ...prevState,
          pointer: page,
          goTo: "",
        }));
      } else {
        swal({
          icon: "error",
          title: "خطا",
          text: response.data.message,
          button: "متوجه شدم",
        });
      }
      dispatch(toggleLoading(false));
    });
  };
  /* ===================================== 
================== Person Modal =================== 
 ===================================== 
*/
  const [personsList, setPersonsList] = useState([]);
  const [personelModal, setPersonelModal] = useState({
    show: false,
    nationalCode: "",
    nationalCodeErr: "",
    nationalCodeIsOk: false,
    showSpeedDial: false,
    showWebcam: false,
  });
  const personelInitialState = {
    ePersonId: "",
    image: "",
    personTypeId: 0,
    codeMelli: "",
    name: "",
    family: "",
    mobile: "",
    fatherName: "",
    shenasnameNumber: "",
    exportFromCityId: 0,
    birthDate: "",
    birthCityId: 0,
    genderId: 0,
    gozarnameNumber: "",
    marriageId: 0,
    tabeiyatId: 0,
    mazhabId: 0,
    bloodGroupId: 0,
    eduDocId: 0,
    eduFieldId: 0,

    estelameAmniyatiId: 0,
    estelameAmniyatiReason: "",
    estelameAmniyatiMailNumber: "",
    estelameAmniyatiFile: "",
    estelameAmniyatiDate: "",

    estelameMaktabiId: 0,
    estelameMaktabiMailNumber: "",
    estelameMaktabiFile: "",
    estelameMaktabiDate: "",

    hasSooePishine: null,
    sooePishineMailNumber: "",
    sooePishineFile: "",
    sooePishineDate: "",

    salahiyateHamkari: "موقت",
    salahiyateHamkariInt: 2,
    hasFormMoshakhasat: null,
    hasTasvirShenasname: null,
    hasTasvirCardMelli: null,
    hasGozarname: null,
    hasEduDoc: null,
    address: "",
    description: "",
  };
  const [personelInputs, setPersonelInputs] = useState(personelInitialState);
  const [personelInputsErr, setPersonelInputsErr] = useState({
    codeMelliErr: "",
  });
  const confirmNationalCode = () => {
    if (personelModal.nationalCode === "") {
      setPersonelModal({
        ...personelModal,
        nationalCodeErr: "مقدار کد ملی نمیتواند خالی باشد",
      });
    } else if (
      personelModal.nationalCode.length !== 10 ||
      !IRCheck.National.isNationalCodeValid(personelModal.nationalCode)
    ) {
      setPersonelModal({
        ...personelModal,
        nationalCodeErr: "لطفا کد ملی معتبر وارد کنید",
      });
    } else {
      AxiosInterceptor.post("/Person/List", {
        page: 1,
        pageSize: 15,
        orderBy: "",
        filter: "codeMelli=" + personelModal.nationalCode,
      }).then((response) => {
        if (response.data.status) {
          if (response.data.persons.count === 0) {
            setPersonelInputs({
              ...personelInputs,
              codeMelli: personelModal.nationalCode,
            });
          } else {
            setPersonelInputs(response.data.persons.data[0]);
          }
          setPersonelModal({
            ...personelModal,
            nationalCodeErr: "",
            nationalCodeIsOk: true,
          });
        } else {
          swal({
            icon: "error",
            title: "خطا",
            text: response.data.message,
            button: "متوجه شدم",
          });
        }
      });
    }
  };
  const personelOnChangeHandle = (e) => {
    let { name, value, type } = e.target;
    if (type === "number") {
      setPersonelInputs({
        ...personelInputs,
        [name]: +value,
      });
    } else if (type === "select-one") {
      if (value === "انتخاب کنید") {
        setPersonelInputs({
          ...personelInputs,
          [name]: null,
        });
      } else if (value === "true") {
        setPersonelInputs({
          ...personelInputs,
          [name]: true,
        });
      } else if (value === "false") {
        setPersonelInputs({
          ...personelInputs,
          [name]: false,
        });
      } else {
        setPersonelInputs({
          ...personelInputs,
          [name]: +value,
        });
      }
    } else {
      if (name === "codeMelli") {
        if (value.length < 11) {
          setPersonelInputs({
            ...personelInputs,
            [name]: value,
          });
        }
      } else {
        setPersonelInputs({
          ...personelInputs,
          [name]: value,
        });
      }
    }
  };
  const editPersonel = (person) => {
    setPersonelInputs(person);
    setPersonelModal({
      ...personelModal,
      show: true,
      nationalCodeIsOk: true,
    });
    getAllDocsOfThisPersonel(person.ePersonId);
    setTahsilatInputs({
      level: 0,
      reshte: "",
      date: "",
      province: 0,
      city: 0,
      university: "",
      average: "",
      description: "",
    });
    getAllTahsilatThisPersonel(person.ePersonId);
  };
  const deletePersonel = (pId) => {
    let alertInput = document.createElement("div");
    ReactDOM.render(
      <Form.Control
        id="adminPassword"
        name="adminPassword"
        type="password"
        autoComplete="new-password"
      />,
      alertInput
    );
    let el = alertInput.firstChild;
    swal("برای حذف پرسنل باید کلمه عبور ادمین را وارد نمایید.", {
      content: el,
      buttons: ["لغو", "حذف"],
    }).then((value) => {
      if (value) {
        AxiosInterceptor.post("/Person/Delete", {
          id: pId,
          password: el.value,
        }).then((response) => {
          if (response.data.status) {
            swal({
              icon: "success",
              title: "موفقیت",
              text: "پرسنل مورد نظر با موفقیت حذف گردید",
              button: "متوجه شدم",
            });
            getAllPersonels();
          } else {
            swal({
              icon: "error",
              title: "خطا",
              text: response.data.message,
              button: "متوجه شدم",
            });
          }
        });
      }
    });
  };
  const ADDorUPDATEpersonel = (actionType) => {
    if (personelInputs.codeMelli === "") {
      setPersonelInputsErr({
        ...personelInputsErr,
        codeMelliErr: "مقدار کد ملی نمیتواند خالی باشد",
      });
    } else if (
      personelInputs.codeMelli.length !== 10 ||
      !IRCheck.National.isNationalCodeValid(personelInputs.codeMelli)
    ) {
      setPersonelInputsErr({
        ...personelInputsErr,
        codeMelliErr: "لطفا کد ملی معتبر وارد کنید",
      });
    } else {
      setPersonelInputsErr({
        codeMelliErr: "",
      });
      let data = {
        ePersonId: personelInputs.ePersonId,
        personTypeId: personelInputs.personTypeId,
        codeMelli: personelInputs.codeMelli,
        name: personelInputs.name,
        family: personelInputs.family,
        mobile: personelInputs.mobile,
        fatherName: personelInputs.fatherName,
        shenasnameNumber: personelInputs.shenasnameNumber,
        exportFromCityId: personelInputs.exportFromCityId,
        birthDate: personelInputs.birthDate ? personelInputs.birthDate : null,
        birthCityId: personelInputs.birthCityId,
        genderId: personelInputs.genderId,
        hasGozarname: personelInputs.hasGozarname,
        gozarnameNumber: personelInputs.gozarnameNumber,
        gozarnameMailNumber: personelInputs.gozarnameMailNumber,
        gozarnameFile: personelInputs.gozarnameFile,
        gozarnameDate: personelInputs.gozarnameDate
          ? personelInputs.gozarnameDate
          : null,
        marriageId: personelInputs.marriageId,
        tabeiyatId: personelInputs.tabeiyatId,
        mazhabId: personelInputs.mazhabId,
        bloodGroupId: personelInputs.bloodGroupId,
        eduDocId: personelInputs.eduDocId,
        eduFieldId: personelInputs.eduFieldId,
        estelameAmniyatiId: personelInputs.estelameAmniyatiId,
        estelameAmniyatiReason: personelInputs.estelameAmniyatiReason,
        estelameAmniyatiMailNumber: personelInputs.estelameAmniyatiMailNumber,
        estelameAmniyatiFile: personelInputs.estelameAmniyatiFile,
        estelameAmniyatiDate: personelInputs.estelameAmniyatiDate
          ? personelInputs.estelameAmniyatiDate
          : null,
        estelameMaktabiId: personelInputs.estelameMaktabiId,
        estelameMaktabiMailNumber: personelInputs.estelameMaktabiMailNumber,
        estelameMaktabiFile: personelInputs.estelameMaktabiFile,
        estelameMaktabiDate: personelInputs.estelameMaktabiDate
          ? personelInputs.estelameMaktabiDate
          : null,
        // salahiyateHamkari: personelInputs.salahiyateHamkari,
        hasFormMoshakhasat: personelInputs.hasFormMoshakhasat,
        hasTasvirShenasname: personelInputs.hasTasvirShenasname,
        hasTasvirCardMelli: personelInputs.hasTasvirCardMelli,
        hasEduDoc: personelInputs.hasEduDoc,
        hasSooePishine: personelInputs.hasSooePishine,
        sooePishineMailNumber: personelInputs.sooePishineMailNumber,
        sooePishineFile: personelInputs.sooePishineFile,
        sooePishineDate: personelInputs.sooePishineDate
          ? personelInputs.sooePishineDate
          : null,
        description: personelInputs.description,
        address: personelInputs.address,
        image: personelInputs.image,
      };
      let msg = "";
      if (actionType === "ADD") {
        delete data.ePersonId;
        msg = "پرسنل مورد نظر با موفقیت ثبت گردید.";
      } else {
        msg = "پرسنل مورد نظر با موفقیت به روز رسانی گردید.";
      }
      dispatch(toggleModalLoading(true));
      AxiosInterceptor.post("/Person/Edit", data).then((response) => {
        if (response.data.status) {
          swal({
            icon: "success",
            title: "موفقیت",
            text: msg,
            button: "متوجه شدم",
          });
          if (actionType === "ADD") {
            setPersonelInputs({
              ...personelInputs,
              ePersonId: response.data.person.ePersonId,
            });
          }
          getAllPersonels();
        } else {
          swal({
            icon: "error",
            title: "خطا",
            text: response.data.message,
            button: "متوجه شدم",
          });
        }
        dispatch(toggleModalLoading(false));
      });
    }
  };
  /* ===================================== 
  ================== PersonDoc =================== 
    ===================================== 
  */
  const getAllDocsOfThisPersonel = (personId) => {
    AxiosInterceptor.post("/PersonDoc/List", { id: personId }).then(
      (response) => {
        if (response.data.status) {
          let amniatiList = [],
            nezaratiList = [],
            maktabiList = [],
            edariList = [];
          response.data.items.forEach((item) => {
            switch (item.eDoc.type) {
              case "Amniyati":
                amniatiList.push(item);
                break;
              case "Nezarati":
                nezaratiList.push(item);
                break;
              case "Maktabi":
                maktabiList.push(item);
                break;
              case "Edari":
                edariList.push(item);
                break;
              // case "Edu":

              //   break;
              default:
                break;
            }
          });
          setPersonDocList({
            amniatiList: amniatiList,
            nezaratiList: nezaratiList,
            maktabiList: maktabiList,
            edariList: edariList,
          });
        } else {
          swal({
            icon: "error",
            title: "خطا",
            text: response.data.message,
            button: "متوجه شدم",
          });
        }
      }
    );
  };
  const iWantToUploadDoc = (docId) => {
    setPersonDoc({
      ...personDoc,
      eDocId: docId,
    });
    document.getElementById("PersonDocInput").click();
  };
  useEffect(() => {
    if (personDoc.image) {
      uploadDoc();
    }
  }, [personDoc.image]);
  const uploadDoc = () => {
    let data = personDoc;
    data.ePersonId = personelInputs.ePersonId;
    AxiosInterceptor.post("/PersonDoc/Add", data).then((response) => {
      if (response.data.status) {
        swal({
          icon: "success",
          title: "موفقیت",
          text: "بارگذاری مدرک با موفقیت انجام شد.",
          button: "متوجه شدم",
        });
        getAllDocsOfThisPersonel(personelInputs.ePersonId);
      } else {
        swal({
          icon: "error",
          title: "خطا",
          text: response.data.message,
          button: "متوجه شدم",
        });
      }
    });
  };
  const deleteDocPerson = (ePersonDocId, docName) => {
    swal({
      title: "مطمئن هستید؟",
      text: " آیا از حذف " + docName + " اطمینان دارید؟ ",
      icon: "warning",
      buttons: ["خیر", "بله"],
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        AxiosInterceptor.post("/PersonDoc/Delete", {
          id: ePersonDocId,
        }).then((response) => {
          if (response.data.status) {
            swal({
              icon: "success",
              title: "موفقیت",
              text: "مدرک مورد نظر با موفقیت حذف گردید",
              button: "متوجه شدم",
            });
            getAllDocsOfThisPersonel(personelInputs.ePersonId);
          } else {
            swal({
              icon: "error",
              title: "خطا",
              text: response.data.message,
              button: "متوجه شدم",
            });
          }
        });
      }
    });
  };
  /* ===================================== 
  ================== Education =================== 
    ===================================== 
  */
  const [tahsilatInputs, setTahsilatInputs] = useState({
    eEducationId: "",
    level: 0,
    reshte: "",
    date: "",
    province: 0,
    city: 0,
    university: "",
    average: "",
    description: "",
  });
  const [tahsilatList, setTahsilatList] = useState([]);
  const tahsilatOnChangeHandle = (e) => {
    let { name, value, type } = e.target;
    if (type === "number" || type === "select-one") {
      setTahsilatInputs({
        ...tahsilatInputs,
        [name]: +value,
      });
    } else {
      setTahsilatInputs({
        ...tahsilatInputs,
        [name]: value,
      });
    }
  };
  const getAllTahsilatThisPersonel = (personId) => {
    AxiosInterceptor.post("/Education/List", { id: personId }).then(
      (response) => {
        if (response.data.status) {
          setTahsilatList(response.data.items);
        } else {
          swal({
            icon: "error",
            title: "خطا",
            text: response.data.message,
            button: "متوجه شدم",
          });
        }
      }
    );
  };
  const ADDorUPDATEJobDetailTahsilat = (actionType) => {
    let temp = {};
    let msg = "";
    if (actionType === "ADD") {
      msg = "تحصیلات مورد نظر با موفقیت ثبت گردید";
      temp = {
        ePersonId: personelInputs.ePersonId,
        level: tahsilatInputs.level,
        reshte: tahsilatInputs.reshte,
        date: tahsilatInputs.date ? tahsilatInputs.date : null,
        province: tahsilatInputs.province,
        city: tahsilatInputs.city,
        university: tahsilatInputs.university,
        average: tahsilatInputs.average.toString(),
        description: tahsilatInputs.description,
      };
    } else {
      temp = {
        eEducationId: tahsilatInputs.eEducationId,
        ePersonId: personelInputs.ePersonId,
        level: tahsilatInputs.level,
        reshte: tahsilatInputs.reshte,
        date: tahsilatInputs.date ? tahsilatInputs.date : null,
        province: tahsilatInputs.province,
        city: tahsilatInputs.city,
        university: tahsilatInputs.university,
        average: tahsilatInputs.average.toString(),
        description: tahsilatInputs.description,
      };
      msg = "تحصیلات مورد نظر با موفقیت به روز رسانی شد";
    }
    AxiosInterceptor.post("/Education/Edit", temp).then((response) => {
      if (response.data.status) {
        swal({
          icon: "success",
          title: "موفقیت",
          text: msg,
          button: "متوجه شدم",
        });
        setTahsilatInputs({
          eEducationId: "",
          level: 0,
          reshte: "",
          date: "",
          province: 0,
          city: 0,
          university: "",
          average: "",
          description: "",
        });
        getAllTahsilatThisPersonel(personelInputs.ePersonId);
      } else {
        swal({
          icon: "error",
          title: "خطا",
          text: response.data.message,
          button: "متوجه شدم",
        });
      }
    });
  };
  const deleteTahsilat = (eEducationId) => {
    let alertInput = document.createElement("div");
    ReactDOM.render(
      <Form.Control id="adminPassword" name="adminPassword" type="password" autoComplete="new-password"/>,
      alertInput
    );
    let el = alertInput.firstChild;
    swal("برای حذف باید کلمه عبور ادمین را وارد نمایید.", {
      content: el,
      buttons: ["لغو", "حذف"],
    }).then((value) => {
      if (value) {
        AxiosInterceptor.post("/Education/Delete", {
          id: eEducationId,
          password: el.value,
        }).then((response) => {
          if (response.data.status) {
            swal({
              icon: "success",
              title: "موفقیت",
              text: "مدرک مورد نظر با موفقیت حذف گردید",
              button: "متوجه شدم",
            });
            getAllTahsilatThisPersonel(personelInputs.ePersonId);
          } else {
            swal({
              icon: "error",
              title: "خطا",
              text: response.data.message,
              button: "متوجه شدم",
            });
          }
        });
      }
    });
  };
  const editTahsilat = (edu) => {
    setTahsilatInputs({
      eEducationId: edu.eEducationId,
      level: edu.level,
      reshte: edu.reshte,
      date: edu.date ? edu.date : "",
      province: edu.province,
      city: edu.city,
      university: edu.university,
      average: edu.average,
      description: edu.description,
    });
  };
  /* ===================================== 
  ========= JobDetail =============
    ===================================== 
  */
  const [jobDetailModal, setJobDetailModal] = useState({
    show: false,
    person: {},
    addingjobMode: false,
    isKhatemeHamkari: false,
    list: [],
  });
  const [jobDetailInputs, setJobDetailInputs] = useState({
    eJobId: "",
    companyId: 0,
    startDate: "",
    endDate: "",
    endStatus: 0,
    projectLevel: 0,
    servicePlaceId: 0,
    description: "",
  });
  const getJobAllDetails = (person) => {
    dispatch(toggleModalLoading(true));
    AxiosInterceptor.post("/Job/List", { id: person.ePersonId }).then(
      (response) => {
        if (response.data.status) {
          setJobDetailModal({
            ...jobDetailModal,
            person: person,
            show: true,
            addingjobMode: false,
            isKhatemeHamkari: false,
            list: response.data.items,
          });
        } else {
          swal({
            icon: "error",
            title: "خطا",
            text: response.data.message,
            button: "متوجه شدم",
          });
        }
        dispatch(toggleModalLoading(false));
      }
    );
  };
  const jobDetailOnChangeHandle = (e) => {
    let { name, value, type } = e.target;
    if (name === "servicePlaceId") {
      let myParent = "";
      staticDatas.PServicePlaceId.forEach((item) => {
        if (item.key === +value) {
          myParent = item.parentValue;
        }
      });
      setJobDetailInputs({
        ...jobDetailInputs,
        servicePlaceId: +value,
        projectLevel: +myParent,
      });
    } else {
      if (type === "number" || type === "select-one") {
        setJobDetailInputs({
          ...jobDetailInputs,
          [name]: +value,
        });
      } else {
        setJobDetailInputs({
          ...jobDetailInputs,
          [name]: value,
        });
      }
    }
  };
  const editjobDetail = (jobDetail) => {
    setJobDetailInputs({
      eJobId: jobDetail.eJobId,
      startDate: jobDetail.startDate,
      endDate: jobDetail.endDate === null ? "" : jobDetail.endDate,
      companyId: jobDetail.companyId,
      endStatus: jobDetail.endStatus,
      projectLevel: jobDetail.projectLevel,
      servicePlaceId: jobDetail.servicePlaceId,
      description: jobDetail.description,
    });
    if (
      jobDetail.endStatus !== 0 ||
      jobDetail.description ||
      jobDetail.endDate
    ) {
      setJobDetailModal({
        ...jobDetailModal,
        addingjobMode: true,
        isKhatemeHamkari: true,
      });
    } else {
      setJobDetailModal({
        ...jobDetailModal,
        addingjobMode: true,
        isKhatemeHamkari: false,
      });
    }
  };
  const ADDorUPDATEJobDetail = (actionType) => {
    let temp = {};
    let msg = "";
    if (actionType === "ADD") {
      temp = {
        ePersonId: jobDetailModal.person.ePersonId,
        companyId: jobDetailInputs.companyId,
        startDate: jobDetailInputs.startDate ? jobDetailInputs.startDate : null,
        endDate: jobDetailInputs.endDate ? jobDetailInputs.endDate : null,
        endStatus: jobDetailInputs.endStatus,
        projectLevel: jobDetailInputs.projectLevel,
        servicePlaceId: jobDetailInputs.servicePlaceId,
        description: jobDetailInputs.description,
      };
      msg = "خلاصه وضعیت همکاری مورد نظر با موفقیت اضافه شد";
    } else {
      temp = {
        eJobId: jobDetailInputs.eJobId,
        ePersonId: jobDetailModal.person.ePersonId,
        companyId: jobDetailInputs.companyId,
        startDate: jobDetailInputs.startDate ? jobDetailInputs.startDate : null,
        endDate: jobDetailInputs.endDate ? jobDetailInputs.endDate : null,
        endStatus: jobDetailInputs.endStatus,
        projectLevel: jobDetailInputs.projectLevel,
        servicePlaceId: jobDetailInputs.servicePlaceId,
        description: jobDetailInputs.description,
      };
      msg = "خلاصه وضعیت همکاری مورد نظر با موفقیت به روز رسانی شد";
    }
    AxiosInterceptor.post("/Job/Edit", temp).then((response) => {
      if (response.data.status) {
        swal({
          icon: "success",
          title: "موفقیت",
          text: msg,
          button: "متوجه شدم",
        });
        getJobAllDetails(jobDetailModal.person);
      } else {
        swal({
          icon: "error",
          title: "خطا",
          text: response.data.message,
          button: "متوجه شدم",
        });
      }
    });
  };
  const deleteJobDetail = (jobDetailId) => {
    let alertInput = document.createElement("div");
    ReactDOM.render(
      <Form.Control id="adminPassword" name="adminPassword" type="password" autoComplete="new-password"/>,
      alertInput
    );
    let el = alertInput.firstChild;
    swal("برای حذف باید کلمه عبور ادمین را وارد نمایید.", {
      content: el,
      buttons: ["لغو", "حذف"],
    }).then((value) => {
      if (value) {
        AxiosInterceptor.post("/Job/Delete", {
          id: jobDetailId,
          password: el.value,
        }).then((response) => {
          if (response.data.status) {
            swal({
              icon: "success",
              title: "موفقیت",
              text: " خلاصه وضعیت همکاری مورد نظر با موفقیت حذف گردید",
              button: "متوجه شدم",
            });
            getJobAllDetails(jobDetailModal.person);
          } else {
            swal({
              icon: "error",
              title: "خطا",
              text: response.data.message,
              button: "متوجه شدم",
            });
          }
        });
      }
    });
  };
  /* ===================================== 
   =======================================
     ===================================== 
   */
  const fileChangeHandler = (e) => {
    let size = (e.target.files[0].size / 1024).toFixed(2);
    let fileName = e.target.files[0].name;
    if (size > 7000) {
      swal({
        icon: "error",
        title: "خطا",
        text: "حداکثر حجم مجاز 7 مگابایت است",
        button: "متوجه شدم",
      });
    } else if (fileName.includes(".exe")) {
      swal({
        icon: "error",
        title: "خطا",
        text: "فایل‌های اجرایی را نمیتوان آپلود کرد",
        button: "متوجه شدم",
      });
    } else {
      dispatch(toggleModalLoading(true));
      let reader = new FileReader();
      reader.onloadend = () => {
        AxiosInterceptor.post("/Setting/Upload", {
          file: reader.result,
          name: fileName,
        }).then((response) => {
          switch (e.target.id) {
            case "PersonDocInput":
              setPersonDoc({
                ...personDoc,
                image: response.data.message,
              });
              break;
            case "personImageInput":
              setPersonelInputs({
                ...personelInputs,
                image: response.data.message,
              });
              break;
            case "estelameMaktabiFileInput":
              setPersonelInputs({
                ...personelInputs,
                estelameMaktabiFile: response.data.message,
              });
              break;
            case "estelameAmniyatiFileInput":
              setPersonelInputs({
                ...personelInputs,
                estelameAmniyatiFile: response.data.message,
              });
              break;
            case "sooePishineFileInput":
              setPersonelInputs({
                ...personelInputs,
                sooePishineFile: response.data.message,
              });
              break;
            default:
              break;
          }
          dispatch(toggleModalLoading(false));
        });
      };
      reader.readAsDataURL(e.target.files[0]);
    }
  };
  const searchByField = (fieldName) => {
    dispatch(toggleModalLoading(true));
    AxiosInterceptor.post("/Person/List", {
      page: 1,
      pageSize: 1,
      orderBy: "",
      filter: `${fieldName}=${personelInputs[fieldName]}`,
    }).then((response) => {
      if (response.data.status) {
        if (response.data.persons.count === 0) {
          swal({
            icon: "error",
            title: "خطا",
            text: " نتیجه ای برای این مقادیر یافت نشد",
            button: "متوجه شدم",
          });
        } else {
          let p = response.data.persons.data[0];
          setPersonelInputs(p);
          getAllDocsOfThisPersonel(p.ePersonId);
          setTahsilatInputs({
            level: 0,
            reshte: "",
            date: "",
            province: 0,
            city: 0,
            university: "",
            average: "",
            description: "",
          });
          getAllTahsilatThisPersonel(p.ePersonId);
        }
      } else {
        swal({
          icon: "error",
          title: "خطا",
          text: response.data.message,
          button: "متوجه شدم",
        });
      }
      dispatch(toggleModalLoading(false));
    });
  };
  const clearModal = () => {
    setPersonelInputs(personelInitialState);
    setPersonelInputsErr({
      codeMelliErr: "",
    });
    setPersonelModal({
      show: true,
      nationalCode: "",
      nationalCodeErr: "",
      nationalCodeIsOk: false,
      showSpeedDial: false,
      showWebcam: false,
    });
    setTahsilatInputs({
      eEducationId: "",
      level: 0,
      reshte: "",
      date: "",
      province: 0,
      city: 0,
      university: "",
      average: "",
      description: "",
    });
  };
  const videoConstraints = {
    width: 220,
    height: 200,
    facingMode: "user",
  };
  const webcamRef = useRef(null);
  const capture = useCallback(() => {
    const imageSrc = webcamRef.current.getScreenshot();
    dispatch(toggleModalLoading(true));
    AxiosInterceptor.post("/Setting/Upload", {
      file: imageSrc,
      name: "webcamImage.jpg",
    }).then((response) => {
      setPersonelInputs({
        ...personelInputs,
        image: response.data.message,
      });
      setPersonelModal((prevState) => ({
        ...prevState,
        showWebcam: false,
      }));
      dispatch(toggleModalLoading(false));
    });
  }, [webcamRef]);
  useEffect(() => {
    getAllPersonels();
    getStaticData();
    getAllStaticDocs();
  }, []);
  useEffect(() => {
    if (+reduxState.personelCount > 0) {
      let arr = [];
      let groupsNumber =
        parseInt(reduxState.personelCount) /
        parseInt(paginationFilter.docNumberPerPage);
      let i = 1;
      while (groupsNumber > 0) {
        groupsNumber--;
        arr.push(i);
        i++;
      }
      setPaginationFilter({
        ...paginationFilter,
        items: arr,
      });
    }
    // let wrapperHeight = document.querySelector(
    //   "#layoutSidenav_content"
    // ).clientHeight;
    // switch (paginationFilter.docNumberPerPage) {
    //   case "5":
    //     tableHeightRef.current.style.maxHeight =
    //       parseInt(wrapperHeight) - 100 + "px";
    //     break;
    //   case "10":
    //     tableHeightRef.current.style.maxHeight =
    //       parseInt(wrapperHeight) - 250 + "px";
    //     break;
    //   case "15":
    //     tableHeightRef.current.style.maxHeight =
    //       parseInt(wrapperHeight) - 300 + "px";
    //     break;
    //   default:
    //     break;
    // }
  }, [paginationFilter.docNumberPerPage, reduxState.personelCount]);
  return (
    <Dashboard>
      <div className="personeli-wrapper p-4">
        <Row className="mb-2">
          <Col
            sm={12}
            lg={4}
            className="d-flex align-item-center justify-content-start"
          >
            <h5 className="m-0 set-column-middle">لیست پرسنل</h5>
          </Col>
          <Col
            sm={12}
            lg={8}
            className="d-flex align-item-center justify-content-end"
          >
            <button
              className="btn btn-success mx-2 text-white"
              onClick={clearModal}
            >
              افزودن پرسنل جدید +
            </button>
          </Col>
        </Row>
        <hr />
        <Row
          ref={tableHeightRef}
          style={{
            overflowY: "auto",
          }}
        >
          <Table striped bordered hover responsive>
            <thead className="gradiantBg text-white text-center">
              <tr>
                <th scope="col" className="text-nowrap">
                  ردیف
                </th>
                <th scope="col" className="text-nowrap">
                  تصویر
                </th>
                <th scope="col" className="text-nowrap">
                  نام
                </th>
                <th scope="col" className="text-nowrap">
                  نام خانوادگی
                </th>
                <th scope="col" className="text-nowrap">
                  کد ملی
                </th>
                <th scope="col" className="text-nowrap">
                  صلاحیت همکاری
                </th>
                <th scope="col" className="text-nowrap">
                  عملیات
                </th>
              </tr>
            </thead>
            <tbody className="text-center">
              {personsList.length > 0 ? (
                personsList?.map((person, index) => {
                  return (
                    <tr key={index} id={person.ePersonId}>
                      <td className="text-nowrap">
                        {parseInt(paginationFilter.pointer) *
                          parseInt(paginationFilter.docNumberPerPage) +
                          (index +
                            1 -
                            parseInt(paginationFilter.docNumberPerPage))}
                      </td>
                      <td className="text-nowrap set-row-middle">
                        <div style={{ width: "40px", height: "40px" }}>
                          <Image
                            className="cpointer zoomable_avatar"
                            src={
                              person.image
                                ? IMAGEURLS + person.image
                                : defProfile
                            }
                            width="40px"
                            height="40px"
                          />
                        </div>
                      </td>
                      <td className="text-nowrap">{person.name}</td>
                      <td className="text-nowrap">{person.family}</td>
                      <td className="text-nowrap">{person.codeMelli}</td>
                      <td className="text-nowrap">
                        {/* 
                          دارد = 0,
                          ندارد = 1,
                          موقت = 2,
                          شناسایی_جعل_سند = 3,
                        */}
                        {person.salahiyateHamkariInt === 0 ||
                          person.salahiyateHamkariInt === 2 ? (
                          <OverlayTrigger
                            placement="top"
                            delay={{ show: 250, hide: 400 }}
                            overlay={
                              <Tooltip>{person.salahiyateHamkari.replaceAll("_", " ")}</Tooltip>
                            }
                          >
                            <span className="text-success cpointer">
                              <CheckLg size="1.3em" />
                            </span>
                          </OverlayTrigger>
                        ) : (
                          <OverlayTrigger
                            placement="top"
                            delay={{ show: 250, hide: 400 }}
                            overlay={
                              <Tooltip>{person.salahiyateHamkari.replaceAll("_", " ")}</Tooltip>
                            }
                          >
                            <span className="text-danger cpointer">
                              <XLg size="1.3em" />
                            </span>
                          </OverlayTrigger>
                        )}
                      </td>
                      <td>
                        <div className="set-row-middle">
                          <span
                            className="mx-2 cpointer text-nowrap"
                            onClick={() => {
                              getJobAllDetails(person);
                            }}
                          >
                            <i className="ms-1">
                              <InfoSquareFill
                                className="text-info"
                                size={"1.2rem"}
                              />
                            </i>
                            خلاصه وضعیت همکاری
                          </span>
                          <span
                            className="mx-1 cpointer text-nowrap"
                            onClick={() => {
                              editPersonel(person);
                            }}
                          >
                            <i className="ms-1">
                              <PencilSquare
                                className="text-warning"
                                size={"1.2rem"}
                              />
                            </i>
                            ویرایش
                          </span>
                          <span
                            className="mx-1 cpointer text-nowrap"
                            onClick={() => {
                              deletePersonel(person.ePersonId);
                            }}
                          >
                            <i className="ms-1">
                              <Trash className="text-danger" size={"1.2rem"} />
                            </i>
                            حذف
                          </span>
                        </div>
                      </td>
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td colSpan={7}>لیست پرسنل خالی میباشد</td>
                </tr>
              )}
            </tbody>
          </Table>
        </Row>
        <Row>
          <Col sm={12} className="px-3 py-3 set-row-middle position-relative">
            <div
              className="input-wrapper flex-nowrap"
              style={{ position: "absolute", right: "5px" }}
            >
              <Form.Group className="set-row-middle">
                <InputGroup.Text>برو به صفحه</InputGroup.Text>
                <FormControl
                  name="goTo"
                  type="number"
                  style={{ maxWidth: "70px" }}
                  value={paginationFilter.goTo}
                  onChange={(e) => {
                    setPaginationFilter({
                      ...paginationFilter,
                      goTo: e.target.value,
                    });
                  }}
                />
                <button
                  className="btn gradiantBg"
                  onClick={() => {
                    if (paginationFilter.goTo !== "") {
                      getThisPagePersonels(parseInt(paginationFilter.goTo));
                    }
                  }}
                >
                  <ArrowLeftCircleFill size="1.3em" color="#fff" />
                </button>
              </Form.Group>
            </div>
            <ul
              id="paginationList"
              className="m-0 p-0 list-unstyled set-row-middle flex-wrap"
            >
              <li
                className="ms-1 cpointer"
                onClick={() => {
                  if (paginationFilter.pointer !== paginationFilter.items[0]) {
                    getThisPagePersonels(paginationFilter.pointer - 1);
                  }
                }}
              >
                <ArrowRight size="1.3em" />
              </li>
              {paginationFilter.pointer !== 1 && (
                <li
                  className="paginationItems"
                  onClick={() => {
                    getThisPagePersonels(1);
                  }}
                >
                  1
                </li>
              )}
              {paginationFilter.pointer > 2 && (
                <li>
                  <strong>.....</strong>
                </li>
              )}
              {paginationFilter.items?.map((pa, index) => {
                return index + 1 === paginationFilter.pointer ||
                  (index <= paginationFilter.pointer + 1 &&
                    index + 1 >= paginationFilter.pointer) ||
                  index + 1 ===
                  paginationFilter.items[
                  paginationFilter.items.length - 1
                  ] ? (
                  <li
                    key={index}
                    className={`paginationItems ${index + 1 === paginationFilter.pointer
                      ? "paginationItemActive"
                      : ""
                      }`}
                    onClick={() => {
                      getThisPagePersonels(pa);
                    }}
                  >
                    {pa}
                  </li>
                ) : index + 1 ===
                  paginationFilter.items[paginationFilter.items.length - 2] ? (
                  <>
                    <li>
                      <strong>.....</strong>
                    </li>
                    <li
                      key={index}
                      className={`paginationItems ${index + 1 === paginationFilter.pointer
                        ? "paginationItemActive"
                        : ""
                        }`}
                      onClick={() => {
                        getThisPagePersonels(pa);
                      }}
                    >
                      {pa}
                    </li>
                  </>
                ) : (
                  ""
                );
              })}

              <li
                className="me-1 cpointer"
                onClick={() => {
                  if (
                    paginationFilter.pointer !==
                    paginationFilter.items[paginationFilter.items.length - 1]
                  ) {
                    getThisPagePersonels(paginationFilter.pointer + 1);
                  }
                }}
              >
                <ArrowLeft size="1.3em" />
              </li>
            </ul>
            <div
              className="input-wrapper flex-nowrap"
              style={{ position: "absolute", left: "5px" }}
            >
              <Form.Group className="set-row-middle">
                <InputGroup.Text>تعداد سند در هر صفحه</InputGroup.Text>
                <Form.Select
                  value={paginationFilter.docNumberPerPage}
                  name="docNumberPerPage"
                  id="docNumberPerPage"
                  onChange={(e) => {
                    let myTake = +e.target.value;
                    dispatch(toggleLoading(true));
                    AxiosInterceptor.post("/Person/List", {
                      page: 1,
                      pageSize: myTake,
                      orderBy: "",
                      filter: "",
                    }).then((response) => {
                      if (response.data.status) {
                        setPersonsList(response.data.persons.data);
                        dispatch(setPersonelCount(response.data.persons.count));
                        setPaginationFilter((prevState) => ({
                          ...prevState,
                          docNumberPerPage: myTake,
                          pointer: 1,
                        }));
                      } else {
                        swal({
                          icon: "error",
                          title: "خطا",
                          text: response.data.message,
                          button: "متوجه شدم",
                        });
                      }
                      dispatch(toggleLoading(false));
                    });
                  }}
                >
                  <option value="5">5</option>
                  <option value="10">10</option>
                  <option value="15">15</option>
                </Form.Select>
              </Form.Group>
            </div>
          </Col>
        </Row>
      </div>
      <Portal show={personelModal.show}>
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter">پرسنل</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            {personelModal.nationalCodeIsOk ? (
              <>
                <Col
                  sm={12}
                  lg={3}
                  className="p-3 display-flex justify-content-center align-items-start"
                >
                  <input
                    type="file"
                    name="image"
                    className="d-none"
                    id="personImageInput"
                    onChange={fileChangeHandler}
                  />
                  <div className="profileImage-wrapper set-column-middle">
                    {personelModal.showSpeedDial && (
                      <ul className="set-column-middle list-unstyled m-0 p-2 speed_dial">
                        <button
                          className="btn btn-warning"
                          onClick={() => {
                            document.querySelector("#personImageInput").click();
                          }}
                        >
                          <CardImage size="1.3em" />
                        </button>
                        <button
                          className="btn btn-warning"
                          onClick={() => {
                            setPersonelModal((prevState) => ({
                              ...prevState,
                              showSpeedDial: false,
                              showWebcam: true,
                            }));
                          }}
                        >
                          <Camera size="1.3em" />
                        </button>
                      </ul>
                    )}
                    {personelModal.showWebcam ? (
                      <div className="webcam-container">
                        <Webcam
                          audio={false}
                          height={200}
                          ref={webcamRef}
                          screenshotFormat="image/jpeg"
                          width={220}
                          videoConstraints={videoConstraints}
                        />
                        <button
                          className="btn btn-success ms-2"
                          onClick={capture}
                        >
                          <CheckLg />
                        </button>
                        <button
                          className="btn btn-danger me-2"
                          onClick={() => {
                            setPersonelModal((prevState) => ({
                              ...prevState,
                              showWebcam: false,
                            }));
                          }}
                        >
                          <XLg />
                        </button>
                      </div>
                    ) : (
                      <img
                        src={
                          personelInputs.image
                            ? IMAGEURLS + personelInputs.image
                            : defProfile
                        }
                        alt="profile"
                      />
                    )}
                    <button
                      className="btn btn-primary"
                      style={{
                        width: "50px",
                        height: "50px",
                        position: "absolute",
                        top: "-20px",
                        right: "-20px",
                        borderRadius: "50%",
                      }}
                      onClick={() => {
                        setPersonelModal((prevState) => ({
                          ...prevState,
                          showSpeedDial: !prevState.showSpeedDial,
                        }));
                      }}
                    >
                      <PencilSquare size="1.3em" />
                    </button>
                  </div>
                  <div id="salahiyat_title" className="mt-2">
                    <h2 className="text-center">صلاحیت همکاری</h2>
                    <h3 className="text-center">
                      {/* 
                          دارد = 0,
                          ندارد = 1,
                          موقت = 2,
                          شناسایی_جعل_سند = 3,
                        */}
                      {personelInputs.salahiyateHamkariInt === 0 ||
                        personelInputs.salahiyateHamkariInt === 2 ? (
                        <strong
                          className="text-success"
                          style={{ marginRight: "-25px" }}
                        >
                          <CheckLg size="1.3em" />
                          {personelInputs.salahiyateHamkari.replaceAll("_", " ")}
                        </strong>
                      ) : (
                        <strong
                          className="text-danger"
                          style={{ marginRight: "-25px" }}
                        >
                          <XLg size="1.3em" />
                          {personelInputs.salahiyateHamkari.replaceAll("_", " ")}
                        </strong>
                      )}
                    </h3>
                  </div>
                </Col>
                <Col sm={12} lg={9} className="set-column-middle">
                  <Row>
                    <Col sm={12} lg={4}>
                      <Form.Group className="input-wrapper">
                        <InputGroup.Text>نام</InputGroup.Text>
                        <FormControl
                          name="name"
                          type="text"
                          value={personelInputs.name}
                          onChange={personelOnChangeHandle}
                        />
                      </Form.Group>
                    </Col>
                    <Col sm={12} lg={4}>
                      <Form.Group className="input-wrapper">
                        <InputGroup.Text
                          className="cpointer"
                          onClick={() => {
                            searchByField("family");
                          }}
                          style={{ border: "1px solid var(--color2)" }}
                        >
                          نام خانوادگی
                        </InputGroup.Text>
                        <FormControl
                          name="family"
                          type="text"
                          value={personelInputs.family}
                          onChange={personelOnChangeHandle}
                        />
                      </Form.Group>
                    </Col>
                    <Col sm={12} lg={4}>
                      <Form.Group className="input-wrapper">
                        <InputGroup.Text
                          className="cpointer"
                          // onClick={() => {
                          //   searchByField("codeMelli");
                          // }}
                          style={
                            personelInputs.ePersonId
                              ? { cursor: "not-allowed" }
                              : { border: "1px solid var(--color2)" }
                          }
                        >
                          کد ملی
                        </InputGroup.Text>
                        <FormControl
                          name="codeMelli"
                          type="text"
                          className={
                            personelInputsErr.codeMelliErr ? "errorBorder" : ""
                          }
                          value={personelInputs.codeMelli}
                          style={
                            personelInputs.ePersonId
                              ? { background: "#E9ECEF", cursor: "not-allowed" }
                              : {}
                          }
                          onChange={
                            personelInputs.ePersonId
                              ? null
                              : personelOnChangeHandle
                          }
                          disabled={personelInputs.ePersonId ? true : false}
                        />
                      </Form.Group>
                      <p className="error text-center">
                        {personelInputsErr.codeMelliErr}
                      </p>
                    </Col>
                    <Col sm={12} lg={12} className="Tablist">
                      {!personelInputs.ePersonId && (
                        <p className="text-info">
                          <ExclamationDiamondFill className="mx-2" />
                          برای آپلود مدارک و تعیین تحصیلات، ابتدا باید اطلاعات
                          پرسنل را ثبت نمایید.
                        </p>
                      )}
                      <input
                        type="file"
                        name="image"
                        className="d-none"
                        id="PersonDocInput"
                        onChange={fileChangeHandler}
                      />
                      <Tabs
                        defaultActiveKey="info"
                        transition={true}
                        className="my-3"
                      >
                        <Tab eventKey="info" title="اطلاعات فردی">
                          <Row>
                            <Col sm={12} lg={4}>
                              <Form.Group className="input-wrapper">
                                <InputGroup.Text>نام پدر</InputGroup.Text>
                                <FormControl
                                  name="fatherName"
                                  type="text"
                                  value={personelInputs.fatherName}
                                  onChange={personelOnChangeHandle}
                                />
                              </Form.Group>
                            </Col>
                            <Col sm={12} lg={4} className="input-wrapper">
                              <InputGroup>
                                <InputGroup.Text>ش شناسنامه</InputGroup.Text>
                                <FormControl
                                  name="shenasnameNumber"
                                  type="text"
                                  value={personelInputs.shenasnameNumber}
                                  onChange={personelOnChangeHandle}
                                />
                              </InputGroup>
                            </Col>
                            <Col sm={12} lg={4} className="input-wrapper">
                              <EnumSelector
                                data={staticDatas}
                                enumKey="PGenders"
                                name="genderId"
                                title="جنسیت"
                                value={personelInputs.genderId}
                                onChange={personelOnChangeHandle}
                              />
                            </Col>
                            <Col sm={12} lg={4} className="input-wrapper">
                              <InputGroup className="flex-nowrap">
                                <InputGroup.Text>تاریخ تولد</InputGroup.Text>
                                <DateInput
                                  name="birthDate"
                                  onChange={(e) => {
                                    setPersonelInputs({
                                      ...personelInputs,
                                      [e.target.name]:
                                        e.target.value.split("T")[0],
                                    });
                                  }}
                                  autoOk={true}
                                  value={
                                    personelInputs.birthDate
                                      ? personelInputs.birthDate
                                      : ""
                                  }
                                />
                              </InputGroup>
                            </Col>
                            <Col sm={12} lg={4} className="input-wrapper">
                              <EnumSelector
                                data={staticDatas}
                                enumKey="PProvince"
                                name="birthCityId"
                                title="محل تولد"
                                value={personelInputs.birthCityId}
                                onChange={personelOnChangeHandle}
                              />
                            </Col>
                            <Col sm={12} lg={4} className="input-wrapper">
                              <EnumSelector
                                data={staticDatas}
                                enumKey="PProvince"
                                name="exportFromCityId"
                                title="صادره"
                                value={personelInputs.exportFromCityId}
                                onChange={personelOnChangeHandle}
                              />
                            </Col>
                            <Col sm={12} lg={4} className="input-wrapper">
                              <InputGroup>
                                <InputGroup.Text
                                  className="cpointer"
                                  onClick={() => {
                                    searchByField("mobile");
                                  }}
                                  style={{ border: "1px solid var(--color2)" }}
                                >
                                  شماره همراه
                                </InputGroup.Text>
                                <FormControl
                                  name="mobile"
                                  type="text"
                                  value={personelInputs.mobile}
                                  onChange={personelOnChangeHandle}
                                />
                              </InputGroup>
                            </Col>
                            <Col sm={12} lg={4} className="input-wrapper">
                              <EnumSelector
                                data={staticDatas}
                                enumKey="PMazhab"
                                name="mazhabId"
                                title="مذهب"
                                value={personelInputs.mazhabId}
                                onChange={personelOnChangeHandle}
                              />
                            </Col>
                            <Col sm={12} lg={4} className="input-wrapper">
                              <EnumSelector
                                data={staticDatas}
                                enumKey="PBloodGroups"
                                name="bloodGroupId"
                                title="گروه خون"
                                value={personelInputs.bloodGroupId}
                                onChange={personelOnChangeHandle}
                              />
                            </Col>
                            <Col sm={12} lg={4} className="input-wrapper">
                              <EnumSelector
                                data={staticDatas}
                                enumKey="PNationality"
                                name="tabeiyatId"
                                title="تابعیت"
                                value={personelInputs.tabeiyatId}
                                onChange={personelOnChangeHandle}
                              />
                            </Col>
                            <Col sm={12} lg={4} className="input-wrapper">
                              <EnumSelector
                                data={staticDatas}
                                enumKey="PMarriageStatus"
                                name="marriageId"
                                title="تاهل"
                                value={personelInputs.marriageId}
                                onChange={personelOnChangeHandle}
                              />
                            </Col>
                            <Col sm={12} lg={4} className="input-wrapper">
                              <EnumSelector
                                data={staticDatas}
                                enumKey="PShakhsiatType"
                                name="personTypeId"
                                title="نوع شخصیت"
                                value={personelInputs.personTypeId}
                                onChange={personelOnChangeHandle}
                              />
                            </Col>
                            <Col sm={12} lg={6} className="input-wrapper">
                              <InputGroup className="mb-1">
                                <InputGroup.Text>آدرس</InputGroup.Text>
                                <FormControl
                                  as="textarea"
                                  aria-label="With textarea"
                                  name="address"
                                  type="text"
                                  value={personelInputs.address}
                                  onChange={personelOnChangeHandle}
                                />
                              </InputGroup>
                            </Col>
                            <Col sm={12} lg={6} className="input-wrapper">
                              <InputGroup className="mb-1">
                                <InputGroup.Text>توضیحات</InputGroup.Text>
                                <FormControl
                                  as="textarea"
                                  aria-label="With textarea"
                                  name="description"
                                  type="text"
                                  value={personelInputs.description}
                                  onChange={personelOnChangeHandle}
                                />
                              </InputGroup>
                            </Col>
                          </Row>
                        </Tab>
                        <Tab eventKey="estelam" title="استعلام ها">
                          <Row>
                            <Col sm={12} lg={4} className="input-wrapper">
                              <EnumSelector
                                data={staticDatas}
                                enumKey="PEstelamat"
                                name="estelameMaktabiId"
                                title="استعلام مکتبی"
                                value={personelInputs.estelameMaktabiId}
                                onChange={personelOnChangeHandle}
                              />
                            </Col>
                            <Col sm={12} lg={4} className="input-wrapper">
                              <InputGroup className="flex-nowrap">
                                <InputGroup.Text>تاریخ</InputGroup.Text>
                                <DateInput
                                  name="estelameMaktabiDate"
                                  onChange={(e) => {
                                    setPersonelInputs({
                                      ...personelInputs,
                                      [e.target.name]:
                                        e.target.value.split("T")[0],
                                    });
                                  }}
                                  autoOk={true}
                                  value={
                                    personelInputs.estelameMaktabiDate
                                      ? personelInputs.estelameMaktabiDate
                                      : ""
                                  }
                                />
                              </InputGroup>
                            </Col>
                            <Col sm={12} lg={4} className="input-wrapper">
                              <InputGroup>
                                <InputGroup.Text>شماره نامه</InputGroup.Text>
                                <FormControl
                                  name="estelameMaktabiMailNumber"
                                  type="text"
                                  value={
                                    personelInputs.estelameMaktabiMailNumber
                                  }
                                  onChange={personelOnChangeHandle}
                                />
                              </InputGroup>
                            </Col>
                            <Col sm={12} lg={6} className="input-wrapper">
                              <InputGroup>
                                <InputGroup.Text>فایل</InputGroup.Text>
                                {personelInputs.estelameMaktabiFile ? (
                                  <>
                                    <a
                                      className="btn btn-success text-white"
                                      href={
                                        IMAGEURLS +
                                        personelInputs.estelameMaktabiFile
                                      }
                                      target="_blank"
                                      rel="noreferrer"
                                    >
                                      <Eye size="1.2em" className="ms-1" />
                                      مشاهده
                                    </a>
                                    <button
                                      className="btn btn-danger text-white"
                                      onClick={() => {
                                        setPersonelInputs({
                                          ...personelInputs,
                                          estelameMaktabiFile: "",
                                        });
                                      }}
                                    >
                                      <Trash2 size="1.2em" className="ms-1" />
                                      حذف
                                    </button>
                                  </>
                                ) : (
                                  <>
                                    <input
                                      type="file"
                                      name="image"
                                      className="d-none"
                                      id="estelameMaktabiFileInput"
                                      onChange={fileChangeHandler}
                                    />
                                    <button
                                      className="btn btn-info text-white"
                                      onClick={() => {
                                        document
                                          .getElementById(
                                            "estelameMaktabiFileInput"
                                          )
                                          .click();
                                      }}
                                    >
                                      <CloudArrowUpFill
                                        size="1.2em"
                                        className="ms-1"
                                      />{" "}
                                      بارگذاری
                                    </button>
                                  </>
                                )}
                              </InputGroup>
                            </Col>
                          </Row>
                          <hr />
                          <Row>
                            <Col sm={12} lg={4} className="input-wrapper">
                              <EnumSelector
                                data={staticDatas}
                                enumKey="PEstelamat"
                                name="estelameAmniyatiId"
                                title="استعلام امنیتی"
                                value={personelInputs.estelameAmniyatiId}
                                onChange={personelOnChangeHandle}
                              />
                            </Col>
                            <Col sm={12} lg={4} className="input-wrapper">
                              <InputGroup className="flex-nowrap">
                                <InputGroup.Text>تاریخ</InputGroup.Text>
                                <DateInput
                                  name="estelameAmniyatiDate"
                                  onChange={(e) => {
                                    setPersonelInputs({
                                      ...personelInputs,
                                      [e.target.name]:
                                        e.target.value.split("T")[0],
                                    });
                                  }}
                                  autoOk={true}
                                  value={
                                    personelInputs.estelameAmniyatiDate
                                      ? personelInputs.estelameAmniyatiDate
                                      : ""
                                  }
                                />
                              </InputGroup>
                            </Col>
                            <Col sm={12} lg={4} className="input-wrapper">
                              <InputGroup>
                                <InputGroup.Text>شماره نامه</InputGroup.Text>
                                <FormControl
                                  name="estelameAmniyatiMailNumber"
                                  type="text"
                                  value={
                                    personelInputs.estelameAmniyatiMailNumber
                                  }
                                  onChange={personelOnChangeHandle}
                                />
                              </InputGroup>
                            </Col>
                            {/* <Col sm={12} lg={4} className="input-wrapper">
                              <InputGroup>
                                <InputGroup.Text>علت</InputGroup.Text>
                                <FormControl
                                  name="estelameAmniyatiReason"
                                  type="text"
                                  value={personelInputs.estelameAmniyatiReason}
                                  onChange={personelOnChangeHandle}
                                />
                              </InputGroup>
                            </Col> */}
                            <Col sm={12} lg={6} className="input-wrapper">
                              <InputGroup>
                                <InputGroup.Text>فایل</InputGroup.Text>
                                {personelInputs.estelameAmniyatiFile ? (
                                  <>
                                    <a
                                      className="btn btn-success text-white"
                                      href={
                                        IMAGEURLS +
                                        personelInputs.estelameAmniyatiFile
                                      }
                                      target="_blank"
                                      rel="noreferrer"
                                    >
                                      <Eye size="1.2em" className="ms-1" />
                                      مشاهده
                                    </a>
                                    <button
                                      className="btn btn-danger text-white"
                                      onClick={() => {
                                        setPersonelInputs({
                                          ...personelInputs,
                                          estelameAmniyatiFile: "",
                                        });
                                      }}
                                    >
                                      <Trash2 size="1.2em" className="ms-1" />
                                      حذف
                                    </button>
                                  </>
                                ) : (
                                  <>
                                    <input
                                      type="file"
                                      name="image"
                                      className="d-none"
                                      id="estelameAmniyatiFileInput"
                                      onChange={fileChangeHandler}
                                    />
                                    <button
                                      className="btn btn-info text-white"
                                      onClick={() => {
                                        document
                                          .getElementById(
                                            "estelameAmniyatiFileInput"
                                          )
                                          .click();
                                      }}
                                    >
                                      <CloudArrowUpFill
                                        size="1.2em"
                                        className="ms-1"
                                      />{" "}
                                      بارگذاری
                                    </button>
                                  </>
                                )}
                              </InputGroup>
                            </Col>
                          </Row>
                          <hr />
                          <Row>
                            <Col sm={12} lg={4} className="input-wrapper">
                              <Form.Group
                                className="set-row-middle"
                                controlId="hasSooePishine"
                              >
                                <InputGroup.Text>سوء پیشینه</InputGroup.Text>
                                <Form.Select
                                  value={personelInputs.hasSooePishine}
                                  name="hasSooePishine"
                                  onChange={personelOnChangeHandle}
                                >
                                  <option value={null}>انتخاب کنید</option>
                                  <option value={true}>دارد</option>
                                  <option value={false}>ندارد</option>
                                </Form.Select>
                              </Form.Group>
                            </Col>
                            <Col sm={12} lg={4} className="input-wrapper">
                              <InputGroup className="flex-nowrap">
                                <InputGroup.Text>تاریخ</InputGroup.Text>
                                <DateInput
                                  name="sooePishineDate"
                                  onChange={(e) => {
                                    setPersonelInputs({
                                      ...personelInputs,
                                      [e.target.name]:
                                        e.target.value.split("T")[0],
                                    });
                                  }}
                                  autoOk={true}
                                  value={
                                    personelInputs.sooePishineDate
                                      ? personelInputs.sooePishineDate
                                      : ""
                                  }
                                />
                              </InputGroup>
                            </Col>
                            <Col sm={12} lg={4} className="input-wrapper">
                              <InputGroup>
                                <InputGroup.Text>شماره نامه</InputGroup.Text>
                                <FormControl
                                  name="sooePishineMailNumber"
                                  type="text"
                                  value={personelInputs.sooePishineMailNumber}
                                  onChange={personelOnChangeHandle}
                                />
                              </InputGroup>
                            </Col>
                            <Col sm={12} lg={6} className="input-wrapper">
                              <InputGroup>
                                <InputGroup.Text>فایل</InputGroup.Text>
                                {personelInputs.sooePishineFile ? (
                                  <>
                                    <a
                                      className="btn btn-success text-white"
                                      href={
                                        IMAGEURLS +
                                        personelInputs.sooePishineFile
                                      }
                                      target="_blank"
                                      rel="noreferrer"
                                    >
                                      <Eye size="1.2em" className="ms-1" />
                                      مشاهده
                                    </a>
                                    <button
                                      className="btn btn-danger text-white"
                                      onClick={() => {
                                        setPersonelInputs({
                                          ...personelInputs,
                                          sooePishineFile: "",
                                        });
                                      }}
                                    >
                                      <Trash2 size="1.2em" className="ms-1" />
                                      حذف
                                    </button>
                                  </>
                                ) : (
                                  <>
                                    <input
                                      type="file"
                                      name="image"
                                      className="d-none"
                                      id="sooePishineFileInput"
                                      onChange={fileChangeHandler}
                                    />
                                    <button
                                      className="btn btn-info text-white"
                                      onClick={() => {
                                        document
                                          .getElementById(
                                            "sooePishineFileInput"
                                          )
                                          .click();
                                      }}
                                    >
                                      <CloudArrowUpFill
                                        size="1.2em"
                                        className="ms-1"
                                      />{" "}
                                      بارگذاری
                                    </button>
                                  </>
                                )}
                              </InputGroup>
                            </Col>
                          </Row>
                        </Tab>
                        <Tab
                          eventKey="tahsilat"
                          title="تحصیلات"
                          disabled={personelInputs.ePersonId ? false : true}
                        >
                          <Row>
                            <Col sm={12} lg={4} className="input-wrapper">
                              <EnumSelector
                                data={staticDatas}
                                enumKey="PDegrees"
                                name="level"
                                title="مدرک تحصیلی"
                                value={tahsilatInputs.level}
                                onChange={tahsilatOnChangeHandle}
                              />
                            </Col>
                            <Col sm={12} lg={4} className="input-wrapper">
                              <EnumSelector
                                data={staticDatas}
                                enumKey="PFieldName"
                                name="reshte"
                                title="رشته تحصیلی"
                                value={tahsilatInputs.reshte}
                                onChange={tahsilatOnChangeHandle}
                              />
                            </Col>
                            <Col sm={12} lg={4} className="input-wrapper">
                              <InputGroup className="flex-nowrap">
                                <InputGroup.Text>تاریخ</InputGroup.Text>
                                <DateInput
                                  name="date"
                                  onChange={(e) => {
                                    setTahsilatInputs({
                                      ...tahsilatInputs,
                                      [e.target.name]:
                                        e.target.value.split("T")[0],
                                    });
                                  }}
                                  autoOk={true}
                                  value={
                                    tahsilatInputs.date
                                      ? tahsilatInputs.date
                                      : ""
                                  }
                                />
                              </InputGroup>
                            </Col>
                            <Col sm={12} lg={4} className="input-wrapper">
                              <InputGroup>
                                <InputGroup.Text>
                                  نام محل تحصیل{" "}
                                </InputGroup.Text>
                                <FormControl
                                  name="university"
                                  type="text"
                                  value={tahsilatInputs.university}
                                  onChange={tahsilatOnChangeHandle}
                                />
                              </InputGroup>
                            </Col>
                            <Col sm={12} lg={4} className="input-wrapper">
                              <EnumSelector
                                data={staticDatas}
                                enumKey="PProvince"
                                name="province"
                                title="استان"
                                value={tahsilatInputs.province}
                                onChange={tahsilatOnChangeHandle}
                              />
                            </Col>
                            <Col sm={12} lg={4} className="input-wrapper">
                              <Form.Group
                                className="set-row-middle"
                                controlId="hasEduDoc"
                              >
                                <InputGroup.Text>شهرستان</InputGroup.Text>
                                <Form.Select
                                  value={tahsilatInputs.city}
                                  name="city"
                                  onChange={tahsilatOnChangeHandle}
                                >
                                  <option value={0}>انتخاب کنید</option>
                                  {staticDatas.PCity?.filter(
                                    (op) =>
                                      +op.parentValue ===
                                      tahsilatInputs.province
                                  ).map((op, index) => {
                                    return (
                                      <option
                                        value={op.key}
                                        key={index}
                                        id={op.key}
                                      >
                                        {op.value}
                                      </option>
                                    );
                                  })}
                                </Form.Select>
                              </Form.Group>
                            </Col>
                            <Col sm={12} lg={3} className="input-wrapper">
                              <InputGroup>
                                <InputGroup.Text>معدل</InputGroup.Text>
                                <FormControl
                                  name="average"
                                  type="number"
                                  value={tahsilatInputs.average}
                                  onChange={tahsilatOnChangeHandle}
                                />
                              </InputGroup>
                            </Col>
                            <Col sm={12} lg={3} className="input-wrapper">
                              {tahsilatInputs.eEducationId ? (
                                <button
                                  className="btn btn-success mx-2 text-white"
                                  onClick={() =>
                                    ADDorUPDATEJobDetailTahsilat("UPDATE")
                                  }
                                >
                                  به روز رسانی تحصیلات
                                </button>
                              ) : (
                                <button
                                  className="btn btn-success mx-2 text-white"
                                  onClick={() =>
                                    ADDorUPDATEJobDetailTahsilat("ADD")
                                  }
                                >
                                  افزودن تحصیلات +
                                </button>
                              )}
                            </Col>
                          </Row>
                          <hr />
                          <Row id="whyTableResponsiveNotWorking">
                            <Col sm={12} lg={12}>
                              <Table striped bordered hover responsive>
                                <thead className="gradiantBg text-white text-center">
                                  <tr>
                                    <th scope="col" className="text-nowrap">
                                      ردیف
                                    </th>
                                    <th scope="col" className="text-nowrap">
                                      مدرک تحصیلی
                                    </th>
                                    <th scope="col" className="text-nowrap">
                                      رشته
                                    </th>
                                    <th scope="col" className="text-nowrap">
                                      تاریخ
                                    </th>
                                    <th scope="col" className="text-nowrap">
                                      نام محل تحصیل
                                    </th>
                                    <th scope="col" className="text-nowrap">
                                      معدل
                                    </th>
                                    <th scope="col" className="text-nowrap">
                                      استان
                                    </th>
                                    <th scope="col" className="text-nowrap">
                                      شهرستان
                                    </th>
                                    <th scope="col" className="text-nowrap">
                                      عملیات
                                    </th>
                                  </tr>
                                </thead>
                                <tbody className="text-center">
                                  {tahsilatList.length > 0 ? (
                                    tahsilatList?.map((tahsil, index) => {
                                      return (
                                        <tr
                                          key={index}
                                          id={tahsil.eEducationId}
                                        >
                                          <td className="text-nowrap">
                                            {index + 1}
                                          </td>
                                          <td className="text-nowrap">
                                            {tahsil.levelDisplay}
                                          </td>
                                          <td className="text-nowrap">
                                            {tahsil.reshteDisplay}
                                          </td>
                                          <td className="text-nowrap">
                                            {tahsil.datePersian}
                                          </td>
                                          <td className="text-nowrap">
                                            {tahsil.university}
                                          </td>
                                          <td className="text-nowrap">
                                            {tahsil.average}
                                          </td>
                                          <td className="text-nowrap">
                                            {tahsil.provinceDisplay}
                                          </td>
                                          <td className="text-nowrap">
                                            {tahsil.cityDisplay}
                                          </td>
                                          <td>
                                            <div className="set-row-middle">
                                              <span
                                                className="mx-1 cpointer text-nowrap"
                                                onClick={() => {
                                                  editTahsilat(tahsil);
                                                }}
                                              >
                                                <i className="ms-1">
                                                  <PencilSquare
                                                    className="text-warning"
                                                    size={"1.2rem"}
                                                  />
                                                </i>
                                                ویرایش
                                              </span>
                                              <span
                                                className="mx-1 cpointer text-nowrap"
                                                onClick={() => {
                                                  deleteTahsilat(
                                                    tahsil.eEducationId
                                                  );
                                                }}
                                              >
                                                <i className="ms-1">
                                                  <Trash
                                                    className="text-danger"
                                                    size={"1.2rem"}
                                                  />
                                                </i>
                                                حذف
                                              </span>
                                            </div>
                                          </td>
                                        </tr>
                                      );
                                    })
                                  ) : (
                                    <tr>
                                      <td colSpan={9}>
                                        لیست تحصیلات خالی میباشد
                                      </td>
                                    </tr>
                                  )}
                                </tbody>
                              </Table>
                            </Col>
                          </Row>
                        </Tab>
                        <Tab
                          eventKey="docs"
                          title="مدارک"
                          disabled={personelInputs.ePersonId ? false : true}
                        >
                          <Row>
                            <Col sm={12} lg={12} className="Tablist">
                              <Tabs
                                defaultActiveKey="amniati"
                                transition={true}
                                className="my-3"
                              >
                                <Tab eventKey="amniati" title="امنیتی">
                                  <Row>
                                    <h5>لیست مدارک :</h5>
                                    {docTypes.amniatiList.length > 0 ? (
                                      docTypes.amniatiList.map(
                                        (item, index) => {
                                          return (
                                            <DocItem
                                              key={index}
                                              item={item}
                                              type="download"
                                              uploadDoc={iWantToUploadDoc}
                                            />
                                          );
                                        }
                                      )
                                    ) : (
                                      <h5 className="text-center mb-0">
                                        لیست مدارک امنیتی خالی میباشد.
                                      </h5>
                                    )}
                                    <hr className="mt-4" />
                                    <h5>لیست مدارک آپلود شده :</h5>
                                    {personDocList.amniatiList.length > 0 ? (
                                      personDocList.amniatiList.map(
                                        (item, index) => {
                                          return (
                                            <DocItem
                                              key={index}
                                              item={item}
                                              type="upload"
                                              deleteDoc={deleteDocPerson}
                                            />
                                          );
                                        }
                                      )
                                    ) : (
                                      <h5 className="text-center mb-0">
                                        لیست مدارک امنیتی آپلود شده خالی میباشد.
                                      </h5>
                                    )}
                                  </Row>
                                </Tab>
                                <Tab eventKey="nezarati" title="نظارتی">
                                  <Row>
                                    <h5>لیست مدارک :</h5>
                                    {docTypes.nezaratiList.length > 0 ? (
                                      docTypes.nezaratiList.map(
                                        (item, index) => {
                                          return (
                                            <DocItem
                                              key={index}
                                              item={item}
                                              type="download"
                                              uploadDoc={iWantToUploadDoc}
                                            />
                                          );
                                        }
                                      )
                                    ) : (
                                      <h5 className="text-center mb-0">
                                        لیست مدارک نظارتی خالی میباشد.
                                      </h5>
                                    )}
                                    <hr className="mt-4" />
                                    <h5>لیست مدارک آپلود شده :</h5>
                                    {personDocList.nezaratiList.length > 0 ? (
                                      personDocList.nezaratiList.map(
                                        (item, index) => {
                                          return (
                                            <DocItem
                                              key={index}
                                              item={item}
                                              type="upload"
                                              deleteDoc={deleteDocPerson}
                                            />
                                          );
                                        }
                                      )
                                    ) : (
                                      <h5 className="text-center mb-0">
                                        لیست مدارک نظارتی آپلود شده خالی میباشد.
                                      </h5>
                                    )}
                                  </Row>
                                </Tab>
                                <Tab eventKey="maktabi" title="مکتبی">
                                  <Row>
                                    <h5>لیست مدارک :</h5>
                                    {docTypes.maktabiList.length > 0 ? (
                                      docTypes.maktabiList.map(
                                        (item, index) => {
                                          return (
                                            <DocItem
                                              key={index}
                                              item={item}
                                              type="download"
                                              uploadDoc={iWantToUploadDoc}
                                            />
                                          );
                                        }
                                      )
                                    ) : (
                                      <h5 className="text-center mb-0">
                                        لیست مدارک مکتبی خالی میباشد.
                                      </h5>
                                    )}
                                    <hr className="mt-4" />
                                    <h5>لیست مدارک آپلود شده :</h5>
                                    {personDocList.maktabiList.length > 0 ? (
                                      personDocList.maktabiList.map(
                                        (item, index) => {
                                          return (
                                            <DocItem
                                              key={index}
                                              item={item}
                                              type="upload"
                                              deleteDoc={deleteDocPerson}
                                            />
                                          );
                                        }
                                      )
                                    ) : (
                                      <h5 className="text-center mb-0">
                                        لیست مدارک مکتبی آپلود شده خالی میباشد.
                                      </h5>
                                    )}
                                  </Row>
                                </Tab>
                                <Tab eventKey="edari" title="اداری">
                                  <Row>
                                    <h5>لیست مدارک :</h5>
                                    {docTypes.edariList.length > 0 ? (
                                      docTypes.edariList.map((item, index) => {
                                        return (
                                          <DocItem
                                            key={index}
                                            item={item}
                                            type="download"
                                            uploadDoc={iWantToUploadDoc}
                                          />
                                        );
                                      })
                                    ) : (
                                      <h5 className="text-center mb-0">
                                        لیست مدارک اداری خالی میباشد.
                                      </h5>
                                    )}
                                    <hr className="mt-4" />
                                    <h5>لیست مدارک آپلود شده :</h5>
                                    {personDocList.edariList.length > 0 ? (
                                      personDocList.edariList.map(
                                        (item, index) => {
                                          return (
                                            <DocItem
                                              key={index}
                                              item={item}
                                              type="upload"
                                              deleteDoc={deleteDocPerson}
                                            />
                                          );
                                        }
                                      )
                                    ) : (
                                      <h5 className="text-center mb-0">
                                        لیست مدارک اداری آپلود شده خالی میباشد.
                                      </h5>
                                    )}
                                  </Row>
                                </Tab>
                              </Tabs>
                            </Col>
                          </Row>
                        </Tab>
                      </Tabs>
                    </Col>
                  </Row>
                </Col>
              </>
            ) : (
              <>
                <Col sm={12} className="text-center mb-2">
                  <h5>کد ملی پرسنل مورد نظر را وارد نمایید</h5>
                </Col>
                <Col sm={12} className="set-col-middle my-1">
                  <Form.Group
                    className="set-row-middle input-wrapper mx-auto"
                    style={{ width: "30%" }}
                  >
                    <InputGroup.Text>کد ملی</InputGroup.Text>
                    <FormControl
                      name="nationalCode"
                      id="nationalCode"
                      type="text"
                      className={
                        personelModal.nationalCodeErr ? "errorBorder" : ""
                      }
                      value={personelModal.nationalCode}
                      onChange={(e) => {
                        if (e.target.value.length < 11) {
                          setPersonelModal({
                            ...personelModal,
                            nationalCode: e.target.value,
                          });
                        }
                      }}
                    />
                  </Form.Group>
                  <p className="error text-center">
                    {personelModal.nationalCodeErr}
                  </p>
                </Col>
                <Col sm={12} className="text-center mt-2">
                  <button
                    className="btn btn-success mx-2 text-white"
                    onClick={confirmNationalCode}
                  >
                    بررسی
                  </button>
                </Col>
              </>
            )}
          </Row>
        </Modal.Body>
        <Modal.Footer>
          {personelModal.nationalCodeIsOk && (
            <div style={{ width: "89%" }}>
              {personelInputs.ePersonId ? (
                <button
                  className="btn btn-success w-100"
                  onClick={() => ADDorUPDATEpersonel("UPDATE")}
                >
                  به روز رسانی اطلاعات
                </button>
              ) : (
                <button
                  className="btn btn-success w-100"
                  onClick={() => ADDorUPDATEpersonel("ADD")}
                >
                  ثبت اطلاعات
                </button>
              )}
            </div>
          )}
          <button
            style={{ width: "9%" }}
            className="btn btn-danger"
            onClick={() =>
              setPersonelModal({
                show: false,
                nationalCode: "",
                nationalCodeErr: "",
                nationalCodeIsOk: false,
                showSpeedDial: false,
                showWebcam: false,
              })
            }
          >
            بستن
          </button>
        </Modal.Footer>
      </Portal>
      <Portal show={jobDetailModal.show}>
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter">
            لیست خلاصه وضعیت همکاری{" "}
            <span className="text-info">
              {jobDetailModal.person.name + " " + jobDetailModal.person.family}
            </span>
          </Modal.Title>
          {jobDetailModal.addingjobMode ? (
            <button
              className="btn btn-warning"
              onClick={() =>
                setJobDetailModal({
                  ...jobDetailModal,
                  addingjobMode: false,
                })
              }
            >
              بازگشت به لیست
            </button>
          ) : (
            <button
              className="btn btn-success"
              onClick={() => {
                setJobDetailModal({
                  ...jobDetailModal,
                  addingjobMode: true,
                });
                setJobDetailInputs({
                  eJobId: "",
                  startDate: "",
                  endDate: "",
                  companyId: 0,
                  endStatus: 0,
                  projectLevel: 0,
                  servicePlaceId: 0,
                  description: "",
                });
              }}
            >
              افزودن +
            </button>
          )}
        </Modal.Header>
        <Modal.Body>
          {jobDetailModal.addingjobMode ? (
            <>
              <Row>
                <Col sm={12} lg={4} className="input-wrapper">
                  <EnumSelector
                    data={staticDatas}
                    enumKey="PCompanyId"
                    name="companyId"
                    title="نام شرکت"
                    value={jobDetailInputs.companyId}
                    onChange={jobDetailOnChangeHandle}
                  />
                </Col>
                <Col sm={12} lg={4} className="input-wrapper">
                  <EnumSelectorHasParent
                    data={staticDatas}
                    enumKey="PServicePlaceId"
                    parentEnumKey="PProjectLevel"
                    name="servicePlaceId"
                    title="محل خدمتی"
                    value={jobDetailInputs.servicePlaceId}
                    onChange={jobDetailOnChangeHandle}
                  />
                </Col>
                {/* <Col sm={12} lg={4} className="input-wrapper">
                  <EnumSelector
                    data={staticDatas}
                    enumKey="PProjectLevel"
                    name="projectLevel"
                    title="طبقه بندی"
                    value={jobDetailInputs.projectLevel}
                    onChange={jobDetailOnChangeHandle} />
                </Col> */}
                <Col sm={12} lg={4} className="input-wrapper">
                  <InputGroup className="flex-nowrap">
                    <InputGroup.Text>تاریخ استخدام</InputGroup.Text>
                    <DateInput
                      name="startDate"
                      onChange={(e) => {
                        setJobDetailInputs({
                          ...jobDetailInputs,
                          [e.target.name]: e.target.value.split("T")[0],
                        });
                      }}
                      autoOk={true}
                      value={
                        jobDetailInputs.startDate
                          ? jobDetailInputs.startDate
                          : ""
                      }
                    />
                  </InputGroup>
                </Col>
                <Col sm={12} lg={4} className="input-wrapper">
                  <div className="checkbox_wrapper set-row-middle">
                    <h5 className="ms-2 mb-0">اتمام همکاری ؟</h5>
                    <input
                      type="checkbox"
                      id="isKhatemeHamkari"
                      checked={jobDetailModal.isKhatemeHamkari}
                      onChange={(e) => {
                        setJobDetailModal({
                          ...jobDetailModal,
                          isKhatemeHamkari: e.target.checked,
                        });
                        if (!e.target.checked) {
                          setJobDetailInputs({
                            ...jobDetailInputs,
                            endDate: "",
                            endStatus: 0,
                            description: "",
                          });
                        }
                      }}
                    />
                    <label
                      htmlFor="isKhatemeHamkari"
                      className="check-box"
                    ></label>
                  </div>
                </Col>
              </Row>
              <Row>
                {jobDetailModal.isKhatemeHamkari && (
                  <>
                    <Col sm={12} lg={4} className="input-wrapper">
                      <InputGroup className="flex-nowrap">
                        <InputGroup.Text>تاریخ خاتمه همکاری</InputGroup.Text>
                        <DateInput
                          name="endDate"
                          onChange={(e) => {
                            setJobDetailInputs({
                              ...jobDetailInputs,
                              [e.target.name]: e.target.value.split("T")[0],
                            });
                          }}
                          autoOk={true}
                          value={
                            jobDetailInputs.endDate
                              ? jobDetailInputs.endDate
                              : null
                          }
                        />
                      </InputGroup>
                    </Col>
                    <Col sm={12} lg={4} className="input-wrapper">
                      <EnumSelector
                        data={staticDatas}
                        enumKey="PEndStatus"
                        name="endStatus"
                        title="علت خاتمه"
                        value={jobDetailInputs.endStatus}
                        onChange={jobDetailOnChangeHandle}
                      />
                    </Col>
                    <Col sm={12} lg={4} className="input-wrapper">
                      <InputGroup className="mb-1">
                        <InputGroup.Text>توضیحات</InputGroup.Text>
                        <FormControl
                          as="textarea"
                          aria-label="With textarea"
                          name="description"
                          type="text"
                          value={jobDetailInputs.description}
                          onChange={jobDetailOnChangeHandle}
                        />
                      </InputGroup>
                    </Col>
                  </>
                )}
              </Row>
              <Row className="mt-3">
                <Col sm={12} lg={12}>
                  {jobDetailInputs.eJobId ? (
                    <button
                      className="btn btn-success w-100"
                      onClick={() => ADDorUPDATEJobDetail("UPDATE")}
                    >
                      به روز رسانی اطلاعات
                    </button>
                  ) : (
                    <button
                      className="btn btn-success w-100"
                      onClick={() => ADDorUPDATEJobDetail("ADD")}
                    >
                      ثبت اطلاعات
                    </button>
                  )}
                </Col>
              </Row>
            </>
          ) : (
            <Row>
              <Table striped bordered hover responsive>
                <thead className="gradiantBg text-white text-center">
                  <tr>
                    <th scope="col" className="text-nowrap">
                      ردیف
                    </th>
                    <th scope="col" className="text-nowrap">
                      نام شرکت
                    </th>
                    <th scope="col" className="text-nowrap">
                      محل خدمتی
                    </th>
                    {/* <th scope="col" className="text-nowrap">
                      طبقه بندی
                    </th> */}
                    <th scope="col" className="text-nowrap">
                      تاریخ استخدام
                    </th>
                    <th scope="col" className="text-nowrap">
                      تاریخ خاتمه همکاری
                    </th>
                    <th scope="col" className="text-nowrap">
                      علت خاتمه
                    </th>
                    <th scope="col" className="text-nowrap">
                      عملیات
                    </th>
                  </tr>
                </thead>
                <tbody className="text-center">
                  {jobDetailModal.list.length > 0 ? (
                    jobDetailModal.list.map((jd, index) => {
                      return (
                        <tr
                          key={index}
                          id={jd.eJobId}
                          style={
                            jd.endStatusDisplay === "امنیتی" ||
                              jd.endStatusDisplay === "مکتبی" ||
                              jd.endStatusDisplay === "نظارتی" ||
                              jd.endStatusDisplay === "اداری"
                              ? {
                                background: "rgba(255,0,0,.7)",
                                fontWeight: "bolder",
                              }
                              : {}
                          }
                        >
                          <td className="text-nowrap">{index + 1}</td>
                          <td className="text-nowrap">{jd.companyIdDisplay}</td>
                          <td className="text-nowrap">
                            {jd.servicePlaceIdDisplay
                              ? jd.servicePlaceIdDisplay
                              : "-"}
                            {" (" + jd.projectLevelDisplay + ") "}
                          </td>
                          {/* <td className="text-nowrap">
                            {jd.projectLevelDisplay}
                          </td> */}
                          <td className="text-nowrap">{jd.startDatePersian}</td>
                          <td className="text-nowrap">{jd.endDatePersian}</td>
                          <td className="text-nowrap">
                            <strong>{jd.endStatusDisplay}</strong>
                          </td>
                          <td>
                            <div className="set-row-middle">
                              <span
                                className="mx-2 cpointer"
                                onClick={() => editjobDetail(jd)}
                              >
                                <i className="ms-1">
                                  <PencilSquare
                                    className="text-warning"
                                    size="1.2rem"
                                  />
                                </i>
                                ویرایش
                              </span>
                              <span
                                className="mx-2 cpointer"
                                onClick={() => {
                                  deleteJobDetail(jd.eJobId);
                                }}
                              >
                                <i className="ms-1">
                                  <Trash
                                    className="text-danger"
                                    size="1.2rem"
                                  />
                                </i>
                                حذف
                              </span>
                            </div>
                          </td>
                        </tr>
                      );
                    })
                  ) : (
                    <tr>
                      <td colSpan={8}>لیست خلاصه وضعیت همکاری خالی میباشد</td>
                    </tr>
                  )}
                </tbody>
              </Table>
            </Row>
          )}
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn btn-danger"
            onClick={() =>
              setJobDetailModal({
                ...jobDetailModal,
                show: false,
              })
            }
          >
            بستن
          </button>
        </Modal.Footer>
      </Portal>
    </Dashboard>
  );
}

export default Personel;
