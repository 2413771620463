import React, { useState } from "react";
import "./Login.scss";
import logo from "../../assets/images/logo.png";
import { useNavigate } from "react-router-dom";
import AxiosInterceptor from "../../utils/AxiosInterceptor";
import swal from "sweetalert";
import { Eye, EyeSlash } from "react-bootstrap-icons";
function Login() {
  const navigate = useNavigate();
  const [showPass, setShowPass] = useState(false);
  const [loginInputs, setLoginInputs] = useState({
    username: "",
    password: "",
  });
  const [loginInputsErr, setLoginInputsErr] = useState({
    usernameErr: "",
    passwordErr: "",
  });
  const onChangeHandle = (e) => {
    setLoginInputs({ ...loginInputs, [e.target.name]: e.target.value });
  };
  const login = (e) => {
    e.preventDefault();
    if (loginInputs.username === "") {
      setLoginInputsErr({
        ...loginInputsErr,
        usernameErr: "مقدار نام کاربری نمیتواند خالی باشد",
      });
    } else if (loginInputs.username === "") {
      setLoginInputsErr({
        ...loginInputsErr,
        passwordErr: "مقدار پسورد نمیتواند خالی باشد",
      });
    } else {
      e.target.disabled = true;
      setLoginInputsErr({
        usernameErr: "",
        passwordErr: "",
      });
      AxiosInterceptor
        .post("/Account/Login", {
          username: loginInputs.username,
          password: loginInputs.password,
        })
        .then((response) => {
          e.target.disabled = false;
          if (response.data.status) {
            if(localStorage.getItem("_data")){
              localStorage.removeItem("_data");
            }
            localStorage.setItem("_data", JSON.stringify(response.data.response));
            navigate("/Dashboard");
          } else {
            swal({
              title: "خطا",
              text: "نام کاربری یا رمز عبور وارد شده اشتباه است",
              icon: "error",
              buttons: "متوجه شدم",
              dangerMode: true,
            });
          }
        });
    }
  };
  return (
    <div className="Login-wrapper">
      <div className="ripple"></div>
      <div className="login-form">
        <div className="indent-a" />
        <div className="indent-b" />
        <div className="form-header">
          <div className="logo-wrapper">
            <img src={logo} alt="logo" />
          </div>
          <h5 className="title">شرکت مهندسی و ساختمان شمس عمران</h5>
          <h4 className="sub_title">سیستم احراز هویت پرسنل</h4>
        </div>
        <form className="form set-column-middle p-4 w-100">
          <input
            type="text"
            className={`form-field ${loginInputsErr.usernameErr ? "errorBorder" : ""
              }`}
            name="username"
            value={loginInputs.username}
            onChange={onChangeHandle}
            placeholder="نام کاربری"
          />
          <p className="error text-nowrap">{loginInputsErr.usernameErr}</p>
          <div className="pass-container w-100">
            <input
              type={showPass ? "text" : "password"}
              name="password"
              value={loginInputs.password}
              placeholder="رمز عبور"
              onChange={onChangeHandle}
              className={`form-field ${loginInputsErr.passwordErr ? "errorBorder" : ""
                }`}
            />
            <p className="error text-nowrap">{loginInputsErr.passwordErr}</p>
            {showPass ? (
              <span
                className="showPassIcon cpointer"
                onClick={() => {
                  setShowPass((prevState) => !prevState);
                }}
              >
                <EyeSlash />
              </span>
            ) : (
              <span
                className="showPassIcon cpointer"
                onClick={() => {
                  setShowPass((prevState) => !prevState);
                }}
              >
                <Eye />
              </span>
            )}
          </div>
          <button className="form-submit" onClick={login}>
            ورود
          </button>
        </form>
      </div>
    </div>
  );
}

export default Login;
