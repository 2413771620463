import React from "react";
import ReactDOM from "react-dom";
import { Modal } from "react-bootstrap";
import Indicator from "../Loading/Indicator";
import { useSelector } from "react-redux";
function Portal(props) {
  const reduxState = useSelector((state) => state);
  return ReactDOM.createPortal(
    <Modal
      {...props}
      size={props.size ? props.size : "xl"}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <div
        className={
          reduxState.modalLoading
            ? "w-100 h-100 position-absolute set-column-middle bg-white"
            : "d-none"
        }
        style={{ zIndex: 99 }}
      >
        <h5>
          <div style={{ width: "20%", height: "100px" }} title="5">
            <Indicator />
          </div>
        </h5>
      </div>
      {props.children}
    </Modal>,
    document.getElementById("modals-wrapper")
  );
}

export default Portal;
